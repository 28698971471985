import React from 'react';
import ContentLoader from 'react-content-loader';

const LiveOrderDetailsLoader = ({ screenWidth }) => {
    const width = screenWidth > 1440 ? 488 : 427;
    const height = screenWidth > 1440 ? 280 : 280;

    return (
        <div className='w-full border border-neutral-300 rounded-xl'>
            <div>
                <ContentLoader
                    width={width}
                    height={height}
                    className='ml-2.5'
                    viewBox={`0 0 ${width} ${height}`}
                    backgroundColor='#f3f3f3'
                    foregroundColor='#ecebeb'>
                    {/* Order number and Order details header */}
                    <rect
                        x='20'
                        y='15'
                        rx='2'
                        ry='2'
                        width='90'
                        height='16'
                    />
                    <rect
                        x={width - 110}
                        y='15'
                        rx='2'
                        ry='2'
                        width='90'
                        height='16'
                    />
                    {/* Order #yHAG and View details */}
                    <rect
                        x='20'
                        y='40'
                        rx='2'
                        ry='2'
                        width='120'
                        height='22'
                    />
                    <rect
                        x={width - 110}
                        y='40'
                        rx='2'
                        ry='2'
                        width='90'
                        height='22'
                    />

                    {/* Ordered dishes and Quantity header */}
                    <rect
                        x='20'
                        y='90'
                        rx='2'
                        ry='2'
                        width='100'
                        height='16'
                    />
                    <rect
                        x={width - 110}
                        y='90'
                        rx='2'
                        ry='2'
                        width='90'
                        height='16'
                    />

                    {/* Dish name and quantity */}
                    <rect
                        x='20'
                        y='115'
                        rx='2'
                        ry='2'
                        width='200'
                        height='22'
                    />
                    <rect
                        x={width - 54}
                        y='115'
                        rx='2'
                        ry='2'
                        width='30'
                        height='22'
                    />

                    {/* Total bill amount */}
                    <rect
                        x='20'
                        y='170'
                        rx='2'
                        ry='2'
                        width='160'
                        height='22'
                    />
                    <rect
                        x={width - 110}
                        y='170'
                        rx='2'
                        ry='2'
                        width='90'
                        height='22'
                    />

                    {/* "Paid" indicator */}
                    <rect
                        x='185'
                        y='170'
                        rx='4'
                        ry='4'
                        width='30'
                        height='22'
                    />

                    {/* Rupee symbol and amount */}
                    <rect
                        x={width - 110}
                        y='170'
                        rx='2'
                        ry='2'
                        width='90'
                        height='22'
                    />
                    <circle
                        cx={width - 100}
                        cy='181'
                        r='8'
                    />

                    <rect
                        x='32'
                        y='210'
                        rx='8'
                        ry='8'
                        width={width - 64}
                        height='45'
                    />
                </ContentLoader>
            </div>
        </div>
    );
};

export default LiveOrderDetailsLoader;
