import APIV2 from '../../api/axios/APIV2';
import APIV3 from '../../api/axios/APIV3';
import APIV5 from '../../api/axios/APIV5';
import store from '../../reduxStore/index';

const { auth } = store.getState();

export const fetchCategoryList = async (selectedRestaurantId, params) => {
    try {
        const response = await APIV5.get(`/restaurants/${selectedRestaurantId}/categories/internal-name`, { params });

        return response.categories;
    } catch (error) {
        console.log(error);

        throw error;
    }
};

export const fetchDishList = async (selectedRestaurantId, params, selectedCategory) => {
    try {
        const response = await APIV5.get(
            `/restaurants/${selectedRestaurantId}/categories/${selectedCategory}/items/internal-name`,
            { params }
        );

        return response.items;
    } catch (error) {
        console.log(error);

        throw error;
    }
};

export const mapItem = async (selectedRestaurantId, params) => {
    try {
        const response = await APIV5.patch(`restaurants/${selectedRestaurantId}/map-items-to-category`, params);
        return response;
    } catch (error) {
        console.log('error while mapping item : ', error);
        throw error;
    }
};

export const copyItem = async (selectedRestaurantId, params) => {
    try {
        const response = await APIV5.patch(`restaurants/${selectedRestaurantId}/copy-items`, params);
        return response;
    } catch (error) {
        throw error;
    }
};

export const moveItem = async (selectedRestaurantId, params) => {
    try {
        const response = await APIV5.patch(`restaurants/${selectedRestaurantId}/move-items`, params);
        return response;
    } catch (error) {
        console.log('error while moving item : ', error);
        throw error;
    }
};

export const unLinkItem = async (selectedRestaurantId, params) => {
    try {
        const response = await APIV5.patch(`restaurants/${selectedRestaurantId}/un-map-items-from-category`, params);
        return response;
    } catch (error) {
        console.log('error while unlinking item : ', error);
        throw error;
    }
};

export const getDishDetails = async (itemId) => {
    try {
        const response = await APIV5.get(`/restaurants/${auth.selectedRestaurantId}/items/${itemId}`);

        return response.item;
    } catch (error) {
        console.log('error : ', error);
        throw error;
    }
};

export const getCategoryDetails = async (selectedRestaurantId, categoryId) => {
    try {
        const response = await APIV5.get(`/restaurants/${selectedRestaurantId}/categories/${categoryId}`);

        return response.categories;
    } catch (error) {
        console.log('error : ', error);

        throw error;
    }
};

export const deleteCategory = async (selectedRestaurantId, categoryId) => {
    try {
        const response = await APIV5.delete(`/restaurants/${selectedRestaurantId}/categories/${categoryId}`);

        return response;
    } catch (error) {
        console.log('error : ', error);
        throw error;
    }
};

export const deleteDish = async (selectedRestaurantId, dishId) => {
    try {
        const response = await APIV5.delete(`/restaurants/${selectedRestaurantId}/items/${dishId}`);

        return response;
    } catch (error) {
        console.log('error : ', error);
        throw error;
    }
};

export const addCategory = async (selectedRestaurantId, body) => {
    try {
        const response = await APIV5.post(`/restaurants/${selectedRestaurantId}/categories`, body);

        return response;
    } catch (error) {
        throw error;
    }
};

export const editCategory = async (selectedRestaurantId, body, categoryId) => {
    try {
        const response = await APIV5.put(`/restaurants/${selectedRestaurantId}/categories/${categoryId}`, body);

        return response;
    } catch (error) {
        console.log('error : ', error);
        throw error;
    }
};

export const addDish = async (selectedRestaurantId, body, categoryId) => {
    try {
        const response = await APIV2.post(`/restaurants/${selectedRestaurantId}/categories/${categoryId}/items`, body);

        return response;
    } catch (error) {
        console.log('error : ', error);

        throw error;
    }
};

export const editDish = async (body, categoryId, dishId) => {
    try {
        const response = await APIV2.put(
            `/restaurants/${auth.selectedRestaurantId}/categories/${categoryId}/items/${dishId}`,
            body
        );

        return response;
    } catch (error) {
        console.log('error : ', error);

        throw error;
    }
};

export const getAllergens = async (selectedRestaurantId) => {
    try {
        const response = await APIV3.get(`/restaurants/${selectedRestaurantId}/allergens`);

        return response;
    } catch (error) {
        console.log('error : ', error);

        throw error;
    }
};

export const getQuantityUnits = async () => {
    try {
        const response = await APIV3.get(`/restaurants/${auth.selectedRestaurantId}/quantity_units`);

        return response;
    } catch (error) {
        console.log('error : ', error);

        throw error;
    }
};

export const getRestaurantAddonGroups = async (selectedRestaurantId, searchQuery, page) => {
    try {
        const response = await APIV2.get(
            `/restaurants/${selectedRestaurantId}/restaurant-addon-groups?keyword=${
                searchQuery ?? ''
            }&per_page=10&page=${page ?? 1}`
        );

        return response;
    } catch (error) {
        console.log('error : ', error);

        throw error;
    }
};

export const getBadgeList = async () => {
    try {
        const response = await APIV3.get(`/restaurants/${auth.selectedRestaurantId}/badges`);

        return response;
    } catch (error) {
        console.log('error : ', error);

        throw error;
    }
};

export const editBadge = async (badgeId, body) => {
    try {
        const response = await APIV3.put(`restaurants/${auth.selectedRestaurantId}/badges/${badgeId}`, body);

        return response;
    } catch (error) {
        console.log('error : ', error);

        throw error;
    }
};

export const addBadge = async (body) => {
    try {
        const response = await APIV3.post(`restaurants/${auth.selectedRestaurantId}/badges`, body);

        return response;
    } catch (error) {
        console.log('error : ', error);

        throw error;
    }
};

export const removeBadge = async (body) => {
    try {
        const response = await APIV3.delete(`restaurants/${auth.selectedRestaurantId}/badges`, { params: body });

        return response;
    } catch (error) {
        console.log('error : ', error);

        throw error;
    }
};

export const rearrangeCategory = async (body) => {
    try {
        const response = await APIV3.patch(`restaurants/${auth.selectedRestaurantId}/categories/change-sequence`, body);

        return response;
    } catch (error) {
        console.log('error : ', error);

        throw error;
    }
};

export const rearrangeDish = async (body) => {
    try {
        const response = await APIV3.patch(`restaurants/${auth.selectedRestaurantId}/items/change-sequence`, body);

        return response;
    } catch (error) {
        console.log('error : ', error);

        throw error;
    }
};

export const fetchAddonList = async (params) => {
    try {
        const response = await APIV2.get(`/restaurants/${auth.selectedRestaurantId}/restaurant-addon-groups`, {
            params,
        });

        return response.restaurant_addon_groups;
    } catch (error) {
        console.log(error);

        throw error;
    }
};

export const createPresetMenu = async (data) => {
    try {
        const response = await APIV3.post(`restaurants/${auth.selectedRestaurantId}/menus`, data);

        return response;
    } catch (error) {
        console.log('error : ', error);

        throw error;
    }
};

export const getFoodType = (category) => {
    if (!!category.vegetarian && !!category.non_vegetarian) {
        return null;
    }
    if (category?.vegetarian || category?.food_type === 'vegetarian') {
        return 'veg';
    }
    if (category?.non_vegetarian || category?.food_type === 'non-vegetarian') {
        return 'nonVeg';
    }
};

export const filterCategorySubCategoryDishId = (categoryDishArr) => {
    const filteredId = categoryDishArr.map((el) => {
        return {
            categoryId: el.category_id,
            dish: el?.dish?.map((dishEl) => {
                return { id: dishEl.item_id };
            }),
            subCategory: el?.sub_categories?.map((el) => {
                return {
                    id: el.category_id,
                    dish: el?.dish?.map((dishEl) => {
                        return { id: dishEl.item_id };
                    }),
                };
            }),
        };
    });

    return filteredId;
};

const formateMoveItemParams = (filteredId, selectedDish, selectedCategoryDish) => {
    function getSourceCategoryId(dishId) {
        let foundCategoryId = null;

        filteredId.some((category) => {
            if (category?.dish?.some((dish) => dish.id === dishId)) {
                foundCategoryId = category.categoryId;
                return true;
            }
            if (category?.subCategory?.some((subCategory) => subCategory?.dish?.some((dish) => dish.id === dishId))) {
                foundCategoryId = category.categoryId;
                return true;
            }
            return false;
        });

        return foundCategoryId;
    }

    const item_details = selectedDish
        .map((itemId) => ({
            item_id: itemId,
            source_category_id: getSourceCategoryId(itemId),
        }))
        .filter((item) => item.source_category_id !== null);

    return {
        item_details,
        destination_category_id: selectedCategoryDish?.category?.category_id,
    };
};

export const mapMoveCopyOperationSave = async (
    selectedRestaurantId,
    dishOperationState,
    selectedCategoryDish,
    selectedDish,
    filteredId
) => {
    const params = {
        item_ids: selectedDish,
        category_id: selectedCategoryDish?.category?.category_id,
    };

    const moveItemParam =
        dishOperationState === 'move' && formateMoveItemParams(filteredId, selectedDish, selectedCategoryDish);

    let response;

    switch (dishOperationState) {
        case 'map':
            response = await mapItem(selectedRestaurantId, params);
            break;

        case 'move':
            response = await moveItem(selectedRestaurantId, moveItemParam);
            break;

        case 'copy':
            response = await copyItem(selectedRestaurantId, params);
            break;

        default:
            break;
    }
    return response;
};
export const fetchOutletMenu = async (params) => {
    try {
        const response = await APIV3.get(`/restaurants/${auth.selectedRestaurantId}/outlets`, { params: params });
        return response;
    } catch (error) {
        console.log(error);

        throw error;
    }
};

export const editPresetMenu = async (selectedRestaurantId) => {
    try {
        const response = await APIV3.get(`/restaurants/${selectedRestaurantId}/menus`);
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const editToggleOutlet = async (selectedRestaurantId, outletId, linking_outlets_ids, unlinking_outlets_ids) => {
    let params = {
        linking_outlets_ids: linking_outlets_ids,
        unlinking_outlets_ids: unlinking_outlets_ids,
    };
    try {
        const response = await APIV3.patch(`/restaurants/${selectedRestaurantId}/menus/${outletId}`, params);
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
};
