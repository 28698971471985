import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { CheckBoxWithoutLabels } from '../../../Components/FormControl/FormControls';
import { Categories } from '../Components/Catalogue/Components/Card';
import { IsMobileScreen } from '../../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';
import { getFoodType } from '../functions';

export default function CategorySection(props) {
    const { categoriesDetails, checkedCategory, setCheckCategory, setSelectedCategoryDish, presetData, page } = props;

    const isMobileScreen = IsMobileScreen();
    const screenWidth = useWindowSize().width;
    const screenHeight = useWindowSize().height;
    const [isHeaderIndeterminate, setIsHeaderIndeterminate] = useState(false);
    const initialSelectionDoneRef = useRef(false);

    // Simplified initialization logic to prevent blinking
    useEffect(() => {
        if (!categoriesDetails || categoriesDetails.length === 0 || initialSelectionDoneRef.current) return;

        // For Catalogue Preset page with preset data
        if (page === 'Catalogue Preset' && presetData?.categories?.length > 0) {
            const presetCategoryIds = presetData.categories
                .map((cat) => cat.category_id)
                .filter((id) => categoriesDetails.some((category) => category.category_id === id));

            if (presetCategoryIds.length > 0) {
                // Find the first valid category from the preset
                const firstPresetCategory = categoriesDetails.find(
                    (category) => category.category_id === presetCategoryIds[0]
                );

                if (firstPresetCategory) {
                    setSelectedCategoryDish(firstPresetCategory);
                    initialSelectionDoneRef.current = true;
                    return;
                }
            }
        }

        // Default behavior for initial selection
        if (checkedCategory.length === 0 && categoriesDetails.length > 0) {
            const firstCategoryId = categoriesDetails[0].category_id;
            // Only set checked category if it's not already set
            if (!checkedCategory.includes(firstCategoryId)) {
                setCheckCategory((prev) => [...prev, firstCategoryId]);
            }
            setSelectedCategoryDish(categoriesDetails[0]);
        } else if (checkedCategory.length > 0) {
            // If categories are already checked, select the first checked one
            const firstCheckedCategory = categoriesDetails.find((category) =>
                checkedCategory.includes(category.category_id)
            );
            if (firstCheckedCategory) {
                setSelectedCategoryDish(firstCheckedCategory);
            }
        }

        initialSelectionDoneRef.current = true;
    }, [categoriesDetails, page, presetData, checkedCategory, setCheckCategory, setSelectedCategoryDish]);

    const areAllCurrentPageItemsSelected = useMemo(() => {
        if (!categoriesDetails || categoriesDetails.length === 0) return false;

        const currentPageIds = categoriesDetails.map((category) => category.category_id);
        const selectedCurrentPageItems = currentPageIds.filter((id) => checkedCategory.includes(id));

        setIsHeaderIndeterminate(
            selectedCurrentPageItems.length > 0 && selectedCurrentPageItems.length < currentPageIds.length
        );

        return currentPageIds.length > 0 && selectedCurrentPageItems.length === currentPageIds.length;
    }, [categoriesDetails, checkedCategory]);

    const selectAll = () => {
        if (!categoriesDetails || categoriesDetails.length === 0) return;

        const currentPageIds = categoriesDetails.map((category) => category.category_id);
        if (areAllCurrentPageItemsSelected) {
            const newCheckedItems = checkedCategory.filter((id) => !currentPageIds.includes(id));
            setCheckCategory(newCheckedItems);
        } else {
            const newIds = currentPageIds.filter((id) => !checkedCategory.includes(id));
            setCheckCategory([...checkedCategory, ...newIds]);
        }
    };

    // Function to handle individual category selection with memoization
    const handleCategorySelection = useCallback(
        (isSelected, data) => {
            if (!data) return;

            if (isSelected) {
                if (!checkedCategory.includes(data.category_id)) {
                    setCheckCategory((prev) => [...prev, data.category_id]);
                    setSelectedCategoryDish(data);
                }
            } else {
                setCheckCategory((prev) => prev.filter((id) => id !== data.category_id));
            }
        },
        [checkedCategory, setCheckCategory, setSelectedCategoryDish]
    );

    const handleCategoryClick = useCallback(
        (data) => {
            if (!data) return;

            const categoryId = data.category_id;
            const isChecked = checkedCategory.includes(categoryId);
            handleCategorySelection(!isChecked, data);
        },
        [checkedCategory, handleCategorySelection]
    );

    return (
        <>
            <div className='min-w-[315px] border-r border-neutral-300 pr-0.5 md:pr-0 md:min-w-full md:border-0 md:pb-16'>
                <div className='flex flex-row items-center mb-4'>
                    <span className='mr-1 text-black paragraph-large-medium'>Categories</span>
                    <span className='paragraph-small-italic text-neutral-500'>{`(Selected - ${checkedCategory.length})`}</span>
                </div>

                <div className='flex flex-row items-center mb-[18px]'>
                    <CheckBoxWithoutLabels
                        onChange={selectAll}
                        isChecked={areAllCurrentPageItemsSelected}
                        isIndeterminate={isHeaderIndeterminate}
                    />
                    <span className='paragraph-small-medium ml-2'>Select/deselect all</span>
                </div>

                <div
                    className='md:overflow-hidden md:h-full md:mb-4'
                    style={{
                        height: isMobileScreen
                            ? 'auto'
                            : screenWidth < 1440
                              ? screenHeight - 376
                              : screenWidth < 2100
                                ? screenHeight - 412
                                : '616px',
                    }}>
                    {categoriesDetails.map((el, index) => {
                        const getSumSubcategoryDish = (subCategories) => {
                            return subCategories.reduce((total, category) => total + category.total_items, 0);
                        };

                        const totalItems = !!el.sub_categories.length
                            ? getSumSubcategoryDish(el.sub_categories)
                            : el.total_items;

                        const isChecked = checkedCategory.includes(el.category_id);

                        return (
                            <div
                                className={`${
                                    index !== categoriesDetails.length - 1 && 'mb-2'
                                } pr-3 w-full md:max-w-full md:mt-2 md:pr-0 md:mb-0`}
                                key={el.category_id || index}>
                                <Categories
                                    img={el.image_url}
                                    title={el.internal_name}
                                    dishes={el.total_items}
                                    totalItems={totalItems}
                                    minWidth='min-w-[273px] md:min-w-0'
                                    type={getFoodType(el)}
                                    isChecked={isChecked}
                                    onChange={(isSelected) => handleCategorySelection(isSelected, el)}
                                    handleClickCategory={() => handleCategoryClick(el)}
                                    subCategoryNumber={el.total_sub_categories}
                                    imageSize='h-[74px] w-[74px]'
                                    isFromSelection={true}
                                    imageMargin='my-2.5'
                                    subCategoryDetails={el.subCategoryDetails}
                                    page='menuManagement'
                                    dropDownCategoryMarginL='ml-2'
                                    dishNumberTextStyle='paragraph-x-small-italic'
                                    titleStyle='paragraph-small-medium mb-1'
                                    contentMarginL='ml-1'
                                    boxPadding='px-3'
                                    dropdownIconSize='max-w-[16px]'
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
