import React, { useRef } from 'react';
import { IsMobileScreen } from '../../../../../../../Constants/Constants';
import useOutsideClick from '../../../../../../../Helper/Hooks/useOutsideClick';
import { ReactComponent as LeftArrowIcon } from '../../../../../../../Assets/chevron-down.svg';

export default function Layout(props) {
    const { children, handleClickClose } = props;

    const isMobileScreen = IsMobileScreen();

    const ref = useRef();

    useOutsideClick(ref, handleClickClose);

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center items-center overflow-auto md:bg-white md:relative'>
                <div
                    className='max-w-[460px] w-full fixed max-h-[96vh] bg-shades-50 px-8 pt-8 md:px-0 md:pt-0 md:pb-20 m-auto md:max-w-full md:relative overflow-auto md:h-auto rounded-lg'
                    ref={ref}>
                    {isMobileScreen && (
                        <div
                            className='paragraph-medium-medium flex flex-row mb-4 cursor-pointer'
                            onClick={() => handleClickClose()}>
                            <LeftArrowIcon className='rotate-90' />
                            <span className='paragraph-medium-medium'>Back to menu management</span>
                        </div>
                    )}
                    <div className='paragraph-large-medium mb-8 md:mb-4'>Re-arrage categories & dishes</div>
                    {children}
                </div>
            </div>
        </>
    );
}
