import React from 'react';
import { ReactComponent as OrderRatingIcon } from '../../../Assets/order-ratings.svg';
import ListViewOrderRating from './ListViewOrderRating';
import { IsMobileScreen } from '../../../Constants/Constants';
import { format } from 'date-fns';
import { ListBlankComponentView } from '../../../Components/BlankComponent/ListBlankComponentView';
import TableLoader from '../../../Components/ContentLoader/TableLoader';
import { TableBlankComponentView } from '../../../Components/BlankComponent/TableBlankComponentView';
import ListViewLoader from '../../../Components/ContentLoader/ListViewLoader';

export default function OrderRatingDetails(props) {
    const {
        toggleCustomerReviewPopup,
        toggleCustomerOfferPopup,
        orderRatingDetails,
        setDeleteReviewId,
        isLoading,
        paginationChanged,
        setCreateOfferDetails,
        fetchOfferDetails,
        setOfferid,
    } = props;

    const isMobileScreen = IsMobileScreen();

    const getRatingColors = (rating) => {
        const fill = rating >= 4 ? '#EBF6F5' : rating >= 3 ? '#FFF5E3' : '#FEF2F2';
        const stroke = rating >= 4 ? '#3D8C82' : rating >= 3 ? '#FFA704' : '#EF4444';
        return { fill, stroke };
    };

    return (
        <>
            {!isMobileScreen ? (
                <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                    <table className='w-full break-words'>
                        <thead>
                            <tr className='bg-neutral-50 text-left paragraph-overline-small text-neutral-700 shadow-innerShadow'>
                                <th className='pl-6 py-3 min-w-[120px] lg:min-w-[108px]'>ORDER ID</th>
                                <th className='px-6 py-3 min-w-[230px] lg:min-w-[177px]'>CUSTOMER NAME</th>
                                <th className='pl-6 py-3 min-w-[162px] lg:min-w-[141px]'>ORDER RATING</th>
                                <th className='px-6 py-3 min-w-[227px] lg:min-w-[222px]'>ORDER FEEDBACK</th>
                                <th className='px-6 py-3 min-w-[147px] lg:min-w-[108px]'>RIDER RATING</th>
                                <th className='px-6 py-3 min-w-[227px] lg:min-w-[108px]'>RIDER FEEDBACK</th>
                                <th className='px-6 py-3 min-w-[230px] lg:min-w-[177px]'>ORDERING PLATFORM</th>
                                <th className='px-6 py-3 min-w-[230px] lg:min-w-[177px]'>OFFER</th>
                                <th className='px-6 py-3 min-w-[157px] lg:min-w-[108px]'>ORDER DATE</th>
                            </tr>
                        </thead>

                        <tbody>
                            {isLoading || paginationChanged ? (
                                <tr>
                                    <td colSpan={9}>
                                        <TableLoader
                                            hasSwitch={false}
                                            columns={7}
                                        />
                                    </td>
                                </tr>
                            ) : orderRatingDetails && orderRatingDetails.length > 0 ? (
                                orderRatingDetails.map((el, index) => {
                                    const { fill: orderIconFill, stroke: orderIconStroke } = getRatingColors(
                                        el.order_rating
                                    );
                                    const { fill: riderIconFill, stroke: riderIconStroke } = getRatingColors(
                                        el.rider_rating
                                    );

                                    return (
                                        <tr
                                            key={index}
                                            className='paragraph-small-regular h-[70px] even:bg-neutral-50 border-t border-neutral-300'>
                                            {el.order_id !== null ? (
                                                <td
                                                    className='px-6 cursor-pointer text-primary-500 hover:underline underline-offset-3'
                                                    onClick={() => {
                                                        toggleCustomerReviewPopup(el.order_id);
                                                        setDeleteReviewId(el.id);
                                                        setCreateOfferDetails((prevState) => ({
                                                            ...prevState,
                                                            id: el.order_id,
                                                        }));
                                                        setOfferid(el?.offer_id);
                                                    }}>
                                                    {el.order_id}
                                                </td>
                                            ) : (
                                                <td className='px-6'>-</td>
                                            )}
                                            {el.customer_name !== null ? (
                                                <td className='px-6'>{el.customer_name}</td>
                                            ) : (
                                                <td className='px-6'>-</td>
                                            )}
                                            {el.order_rating !== null ? (
                                                <td className='px-6'>
                                                    <div className='flex flex-row items-center'>
                                                        <OrderRatingIcon
                                                            fill={orderIconFill}
                                                            stroke={orderIconStroke}
                                                        />
                                                        <span className='ml-1'>{el.order_rating}</span>
                                                    </div>
                                                </td>
                                            ) : (
                                                <td className='px-6'>
                                                    <div className='flex flex-row items-center'>
                                                        <span className='ml-1'>-</span>
                                                    </div>
                                                </td>
                                            )}

                                            {el.order_feedback !== null ? (
                                                <td className='px-6'>{el.order_feedback}</td>
                                            ) : (
                                                <td className='px-6'>-</td>
                                            )}
                                            {!!el.rider_rating ? (
                                                <td className='px-6'>
                                                    <div className='flex flex-row items-center'>
                                                        <OrderRatingIcon
                                                            fill={riderIconFill}
                                                            stroke={riderIconStroke}
                                                        />
                                                        <span className='ml-1'>{el.rider_rating}</span>
                                                    </div>
                                                </td>
                                            ) : (
                                                <td className='px-6'>
                                                    <div className='flex flex-row items-center'>
                                                        <span className='ml-1'>-</span>
                                                    </div>
                                                </td>
                                            )}
                                            {el.rider_feedback !== null ? (
                                                <td className='px-6'>{el.rider_feedback}</td>
                                            ) : (
                                                <td className='px-6'>-</td>
                                            )}
                                            <td className='px-6'>{el.platform}</td>
                                            {el.offer_id !== null ? (
                                                <td
                                                    className='px-6 cursor-pointer text-primary-500 hover:underline underline-offset-3'
                                                    onClick={() => {
                                                        toggleCustomerOfferPopup();
                                                        fetchOfferDetails(el.offer_id);
                                                    }}>
                                                    Created
                                                </td>
                                            ) : (
                                                <td className='px-6'>Not Created</td>
                                            )}
                                            {el.order_date !== null ? (
                                                <td className='px-6'>
                                                    {format(new Date(el.order_date), 'dd MMM yyyy')}
                                                </td>
                                            ) : (
                                                <td className='px-6'>-</td>
                                            )}
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={9}>
                                        <TableBlankComponentView previewText={'No Content Available'} />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            ) : isLoading || paginationChanged ? (
                <div>
                    {[...Array(10)].map((_, index) => (
                        <ListViewLoader
                            key={index}
                            hasSwitch={false}
                        />
                    ))}
                </div>
            ) : (
                <div className='mb-12 mt-4'>
                    {orderRatingDetails && orderRatingDetails.length > 0 ? (
                        orderRatingDetails.map((el, index) => (
                            <ListViewOrderRating
                                key={el.id}
                                offerId={el.offer_id}
                                id={el.id}
                                setDeleteReviewId={setDeleteReviewId}
                                customerName={el.customer_name}
                                riderRating={el.rider_rating}
                                orderRating={el.order_rating}
                                orderId={el.order_id}
                                orderFeedback={el.order_feedback}
                                riderFeedback={el.rider_feedback}
                                setOfferid={setOfferid}
                                orderDate={format(new Date(el.order_date), 'dd MMM yyyy')}
                                platform={el.platform}
                                offer={el.offer_id}
                                toggleCustomerReviewPopup={toggleCustomerReviewPopup}
                                toggleCustomerOfferPopup={toggleCustomerOfferPopup}
                                setCreateOfferDetails={setCreateOfferDetails}
                                fetchOfferDetails={fetchOfferDetails}
                                index={index}
                            />
                        ))
                    ) : (
                        <ListBlankComponentView previewText={'No Content Available'} />
                    )}
                </div>
            )}
        </>
    );
}
