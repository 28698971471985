import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '@uidotdev/usehooks';
import { useSelector } from 'react-redux';
import ListLoader from '../../../Components/ListLoader';
import HasMoreCard from '../../../Components/HasMoreCard';
import { Dishes } from '../../../Components/Card';
import { getFoodType, fetchDishList, filterCategorySubCategoryDishId } from '../../../../../functions';
import SubCategoryDishList from './SubCategoryDishList';
import { CheckBoxWithoutLabels } from '../../../../../../../Components/FormControl/FormControls';

export default function DishSection(props) {
    const {
        setSelectedCategoryDish,
        selectedCategoryDish,
        setSelectedDish,
        selectedDish,
        selectedCategory,
        setAllCategoryDish,
        allCategoryDish,
        setSelectedCategory,
    } = props;

    const navigate = useNavigate();

    const handleClickEditCategoryDish = () => {
        navigate('/menu/add-dish', { state: true });
    };

    const [isLoading, setIsLoading] = useState(true);

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const screenSize = useWindowSize();

    const initialPageState = { page: 1, per_page: 10, has_more_pages: null };

    const [paginationState, setPaginationState] = useState(initialPageState);

    const [dishList, setDishList] = useState([]);

    const checkDishExist = (params) => {
        const currentDish = allCategoryDish.find((el) => el.category_id === selectedCategoryDish.category.category_id);

        const hasDish = !!currentDish?.dish?.length;

        hasDish && setDishList(currentDish.dish);

        if (selectedCategoryDish?.category?.total_items > currentDish?.dish?.length && hasDish) {
            return false;
        }

        return hasDish;
    };

    const setDishData = (page, dishData) => {
        if (page === 1) {
            setDishList(dishData);

            setAllCategoryDish((prevState) =>
                prevState.map((el) => {
                    if (el.category_id === selectedCategoryDish.category.category_id) {
                        return { ...el, dish: dishData };
                    }
                    return el;
                })
            );
        } else {
            setDishList((prevState) => [...prevState, ...dishData]);

            setAllCategoryDish((prevState) =>
                prevState.map((el) => {
                    if (el.category_id === selectedCategoryDish.category.category_id) {
                        return { ...el, dish: [...el.dish, ...dishData] };
                    }
                    return el;
                })
            );
        }

        page === 1 && setSelectedCategoryDish((prevState) => ({ ...prevState, dish: dishData?.[0]?.item_id }));
    };

    const fetchDish = async (params) => {
        const hasDish = checkDishExist(params);

        if (hasDish) return;

        setIsLoading(true);

        try {
            const dish = await fetchDishList(selectedRestaurantId, params, selectedCategoryDish.category.category_id);

            setIsLoading(false);

            setPaginationState((prevState) => ({
                ...prevState,
                has_more_pages: dish?.has_more_pages,
                last_page: dish?.last_page,
            }));

            setDishData(params.page, dish?.data);

            setIsLoading(false);
        } catch (error) {
            console.log('error : ', error);
        }
    };

    useEffect(() => {
        setPaginationState(initialPageState);

        !!selectedCategoryDish.category && fetchDish(initialPageState);
    }, [selectedCategoryDish?.category?.category_id]);

    const containerRef = useRef(null);

    const handleScroll = useCallback(() => {
        const container = containerRef.current;

        if (container) {
            const { scrollTop, clientHeight, scrollHeight } = container;

            if (scrollTop + clientHeight === scrollHeight && paginationState.last_page !== paginationState.page) {
                fetchDish({ ...paginationState, page: paginationState.page + 1 });

                setPaginationState((prevState) => ({ ...prevState, page: prevState.page + 1 }));
            }
        }
    }, [paginationState]);

    const onSelectDish = (dish) => {
        const newSelectedDishState = !selectedDish.includes(dish.item_id)
            ? [...selectedDish, dish.item_id]
            : selectedDish.filter((el) => el !== dish.item_id);

        setSelectedDish(newSelectedDishState);

        const filteredId = filterCategorySubCategoryDishId(allCategoryDish);

        const selectedCategoryDishId = filteredId.find(
            (el) => el.categoryId === selectedCategoryDish.category.category_id
        );

        const isAllDishesSelected = selectedCategoryDishId.dish
            .map((el) => el.id)
            .every((element) => newSelectedDishState.includes(element));

        const newSelectedCategoryState = isAllDishesSelected
            ? [...selectedCategory, selectedCategoryDish.category.category_id]
            : selectedCategory.filter((el) => el !== selectedCategoryDish.category.category_id);

        setSelectedCategory(newSelectedCategoryState);
    };

    const dishIds = dishList?.map((el) => el.item_id);

    const handleAllSelect = (isSelected) => {
        if (selectedCategoryDish.subCategory.length) {
            const filteredId = filterCategorySubCategoryDishId(allCategoryDish);

            const subCategory = filteredId.find(
                (el) => el.categoryId === selectedCategoryDish.category.category_id
            )?.subCategory;

            const dishList = subCategory.flatMap((d) => d.dish.map((dish) => dish.id));

            isSelected
                ? setSelectedDish((prevState) => [...prevState, ...dishList])
                : setSelectedDish((prevState) => prevState.filter((id) => !dishList.includes(id)));

            isSelected
                ? setSelectedCategory((prevState) => [...prevState, selectedCategoryDish.category.category_id])
                : setSelectedCategory((prevState) =>
                      prevState.filter((id) => id !== selectedCategoryDish.category.category_id)
                  );

            return;
        }

        if (isSelected) {
            setSelectedDish((prevState) => [...prevState, ...dishIds]);
        } else {
            setSelectedDish((prevState) => prevState.filter((id) => !dishIds.includes(id)));
        }
    };

    const isAllDishSelected = () => {
        const filteredId = filterCategorySubCategoryDishId(allCategoryDish);

        if (selectedCategoryDish?.subCategory?.length) {
            const subCategory = filteredId?.find(
                (el) => el.categoryId === selectedCategoryDish.category.category_id
            )?.subCategory;

            const allDishId = subCategory?.flatMap((el) => el?.dish?.map((dishEl) => dishEl.id));

            const isFullSelected = allDishId?.every((id) => selectedDish?.includes(id));

            return isFullSelected;
        }

        return !!dishIds.length && dishIds?.every((id) => selectedDish?.includes(id));
    };

    return (
        <>
            <div className='flex flex-row justify-between items-center w-full mb-4'>
                <span className='paragraph-large-medium text-[#000000]'>Dishes</span>
            </div>

            <div className='flex flex-row items-center gap-1 pb-1'>
                <CheckBoxWithoutLabels
                    isChecked={isAllDishSelected()}
                    onChange={(isSelected) => handleAllSelect(isSelected)}
                />
                <span className='paragraph-small-medium'>Select/deselect all</span>
            </div>

            {!!selectedCategoryDish?.subCategory?.length ? (
                <SubCategoryDishList
                    setSelectedCategoryDish={setSelectedCategoryDish}
                    selectedCategoryDish={selectedCategoryDish}
                    handleClickEditCategoryDish={handleClickEditCategoryDish}
                    pageState={{ selection: true }}
                    onSelectDish={(dish) => onSelectDish(dish)}
                    selectedDish={selectedDish}
                    allCategoryDish={allCategoryDish}
                    setAllCategoryDish={setAllCategoryDish}
                />
            ) : (
                <>
                    <div
                        ref={containerRef}
                        onScroll={handleScroll}
                        className='overflow-auto scrollbar-style pr-8'
                        style={{ height: screenSize.height - 274 }}>
                        {isLoading && paginationState.page === 1 && (
                            <ListLoader
                                className='min-w-[273px] h-[104px]'
                                number={6}
                            />
                        )}

                        {!isLoading && !dishList.length && (
                            <div
                                className='m-auto flex justify-center min-w-[411px] items-center text-xl font-bold '
                                style={{ height: screenSize.height - 274 }}>
                                No dishes are available
                            </div>
                        )}

                        {dishList?.map((el, index) => {
                            return (
                                <div
                                    className={`mt-4 min-w-[273px] lg:min-w-0 md:pl-2.5`}
                                    key={index}>
                                    <Dishes
                                        handleClickEdit={handleClickEditCategoryDish}
                                        isEdit={true}
                                        title={el.internal_name}
                                        image={el.image_url}
                                        variants={el.variants_count}
                                        handleClickDish={() =>
                                            setSelectedCategoryDish((prevState) => ({ ...prevState, dish: el.item_id }))
                                        }
                                        isActive={false}
                                        isRearrange={false}
                                        type={getFoodType(el)}
                                        isSelectionActive={true}
                                        onSelectDish={(isSelected) => onSelectDish(el, isSelected)}
                                        isChecked={selectedDish.includes(el.item_id)}
                                    />
                                </div>
                            );
                        })}

                        {isLoading && paginationState.page !== 1 && <ListLoader className='max-w-[273px] h-[104px]' />}

                        {paginationState?.has_more_pages && (
                            <ListLoader
                                className='max-w-[303px] h-[104px] mt-3'
                                number={1}
                            />
                        )}
                    </div>
                </>
            )}
        </>
    );
}
