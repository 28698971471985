import React, { useEffect, useState } from 'react';
import PaginationWithNumber from '../../../../Components/Pagination/PaginationWithNumber';
import LinkOutletPopup from './Components/LinkOutlet';
import PresetDetails from './Components/PresetDetails';
import { IsMobileScreen } from '../../../../Constants/Constants';
import SelectCategoriesDishesPopup from '../../Popups/SelectCategoriesDishesPopup';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import HeaderCataloguePreset from './Components/HeaderCataloguePreset';
import { CategoryProvider } from '../Catalogue/Context/CategoryContext';
import APIV3 from '../../../../api/axios/APIV3';
import PresetOutletRemovalPopup from './Components/PresetOutletRemovalPopup';

export default function CataloguePreset(props) {
    const { handleClickCreatedBy } = props;
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const [paginationState, setPaginationState] = useState({ resultPerPage: 10, selectedPage: 1, lastPage: 1 });
    const [showLinkOutlet, setShowLinkOutlet] = useState(false);
    const [presetDetail, setPresetDetail] = useState();
    const [isLoading, setIsLoading] = useState();
    const [selectedPresetForLink, setSelectedPresetForLink] = useState();
    const [showCancelDelete, setShowCancelDelete] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [showCheckbox, setShowCheckbox] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [isDisable, setIsDisable] = useState(true);
    const [isOutletsLoading, setIsOutletsLoading] = useState(false);
    const [popup, setPopup] = useState(false);
    const [deleteApiError, setDeleteApiError] = useState(null);
    const handlePagination = (data, type) => {
        if (type === 'selectedPage') {
            setPaginationState((prevState) => ({ ...prevState, ...data }));
            return;
        }
        setPaginationState((prevState) => ({ ...prevState, ...data, selectedPage: 1 }));
    };

    const handleClickLinkOutlet = (item) => {
        setSelectedPresetForLink(item);
        setShowLinkOutlet(!showLinkOutlet);
    };

    const [showSelectCategoryDishesPopUp, setShowSelectCategoryDishesPopUp] = useState(false);

    useEffect(() => {
        if (checkedItems.length > 0) {
            setIsDisable(false);
        } else {
            setIsDisable(true);
        }
    }, [checkedItems]);

    useEffect(() => {
        setShowCancelDelete(false);
        setShowCheckbox(false);
    }, [isDeleted]);

    const handleClickCancel = () => {
        setShowCancelDelete(!showCancelDelete);
        setShowCheckbox(!showCheckbox);
        setCheckedItems([]);
    };

    const handleBulkSelect = () => {
        setShowCancelDelete(!showCancelDelete);
        setShowCheckbox(!showCheckbox);
    };

    const handleCheckboxChange = (id, isChecked) => {
        if (isChecked) {
            setCheckedItems([...checkedItems, id]);
        } else {
            setCheckedItems(checkedItems.filter((item) => item !== id));
        }
    };
    const [searchedPreset, setSearchedPreset] = useState(null);
    const fetchOPresetList = async (params) => {
        try {
            setIsLoading(true);
            const response = await APIV3.get(`/restaurants/${selectedRestaurantId}/menus`, {
                params: params,
            });
            setPresetDetail(response?.menus?.data);

            setPaginationState((prevState) => ({
                ...prevState,
                lastPage: response.menus.last_page,
                selectedPage: response.menus.current_page,
            }));
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        let params = {
            page: paginationState.selectedPage,
            per_page: paginationState.resultPerPage,
        };

        if (searchedPreset) {
            params.keyword = searchedPreset;
        }

        fetchOPresetList(params);
    }, [
        paginationState.resultPerPage,
        paginationState.selectedPage,
        searchedPreset,
        //  deleted,
        selectedRestaurantId,
    ]);
    const [getPresetMenuId, setGetPresetMenuId] = useState(null);
    const [getPresetMenuDetails, setGetPresetMenuDetails] = useState({});
    const handleClickPreset = (id) => {
        setGetPresetMenuId(id);
        setShowSelectCategoryDishesPopUp(!showSelectCategoryDishesPopUp);
    };
    const fetchPresetDetails = async (presetId) => {
        // Define fetchPresetDetails outside useEffect
        if (!presetId) return;
        try {
            // setIsLoading(true); // Decide if you want to show loading for preset details fetch
            const response = await APIV3.get(`/restaurants/${selectedRestaurantId}/menus/${presetId}`);
            setGetPresetMenuDetails(response?.menu);
            // setIsLoading(false);
        } catch (error) {
            console.error('Error fetching preset details:', error);
        }
    };

    useEffect(() => {
        if (getPresetMenuId) {
            // Fetch preset details only if getPresetMenuId is not null
            fetchPresetDetails(getPresetMenuId);
        }
    }, [getPresetMenuId]);

    const closeErrorPopup = () => {
        setPopup(false);
        setDeleteApiError(null);
    };
    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <CategoryProvider>
                <HeaderCataloguePreset
                    setIsDeleted={setIsDeleted}
                    setDeleteApiError={setDeleteApiError}
                    setPopup={setPopup}
                    checkedItems={checkedItems}
                    showCancelDelete={showCancelDelete}
                    isDisable={isDisable}
                    handleClickCancel={handleClickCancel}
                    fetchOPresetList={fetchOPresetList}
                    handleBulkSelect={handleBulkSelect}
                    setSearchedPreset={setSearchedPreset}
                />
                <PresetDetails
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    showCheckbox={showCheckbox}
                    selectedRestaurantId={selectedRestaurantId}
                    setPresetDetail={setPresetDetail}
                    presetDetail={presetDetail}
                    handleCheckboxChange={handleCheckboxChange}
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                    handleClickPreset={handleClickPreset}
                    handleClickLinkOutlet={handleClickLinkOutlet}
                    handleClickCreatedBy={handleClickCreatedBy}
                />

                {!isMobileScreen && !_.isEmpty(presetDetail) && (
                    <div className='mt-4 pb-10'>
                        <PaginationWithNumber
                            lastPage={paginationState.lastPage}
                            selectedPage={paginationState.selectedPage}
                            setSelectedPage={(value) => {
                                handlePagination({ selectedPage: value }, 'selectedPage');
                            }}
                            setResultPerPage={(value) => handlePagination({ resultPerPage: value }, 'resultPerPage')}
                            selectedResultPerpage={paginationState.resultPerPage}
                            isLoading={isLoading}
                        />
                    </div>
                )}

                {showLinkOutlet && (
                    <LinkOutletPopup
                        fetchOPresetList={fetchOPresetList}
                        selectedRestaurantId={selectedRestaurantId}
                        isOutletsLoading={isOutletsLoading}
                        selectedPresetForLink={selectedPresetForLink}
                        headerBottomLine='Link the menu with the desired outlets'
                        page='cataloguePreset'
                        handleClickClose={handleClickLinkOutlet}
                    />
                )}

                {showSelectCategoryDishesPopUp && (
                    <SelectCategoriesDishesPopup
                        page='Catalogue Preset'
                        getPresetMenuDetails={getPresetMenuDetails}
                        handleClickClose={handleClickPreset}
                    />
                )}

                {popup && deleteApiError.length > 0 && (
                    <PresetOutletRemovalPopup
                        errorMessage={deleteApiError}
                        onClose={closeErrorPopup}
                    />
                )}
            </CategoryProvider>
        </>
    );
}
