import React, { useEffect, useState, useRef, useCallback } from 'react';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import { ReactComponent as LeftArrowIcon } from '../../../Assets/chevron-down.svg';
import { IsMobileScreen } from '../../../Constants/Constants';
import CategorySection from './CategorySection';
import DishSection from './DishSection';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../Components/Buttons/Button';
import { DefaultInputField } from '../../../Components/InputField/InputField';
import { useCategoryContext } from '../Components/Catalogue/Context/CategoryContext';
import { createPresetMenu, editPresetMenu } from '../functions';
import { useWindowSize } from '@uidotdev/usehooks';

export default function SelectCategoriesDishesPopup(props) {
    const { handleClickClose, page, presetName, getPresetMenuDetails } = props;
    const screenSize = useWindowSize();
    const { categoryList, fetchCategory, paginationState, isLoading, setPaginationState } = useCategoryContext();
    const [showDishesPopup, setShowDishesPopup] = useState(true);
    const containerRef = useRef(null);
    const [checkedCategory, setCheckCategory] = useState([]);
    const [isDisable, setIsDisable] = useState(true);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [isInputFieldDisable, setIsInputFieldDisable] = useState(true);
    // Global dish cache to persist between category selections
    const [globalDishCache, setGlobalDishCache] = useState({});
    const [presetData, setPresetData] = useState({
        name: presetName,
        categories: [],
    });
    const prevCheckedCategoryRef = useRef([]);
    const initialLoadDoneRef = useRef(false);

    const handleScroll = useCallback(() => {
        const container = containerRef.current;
        if (container) {
            const { scrollTop, clientHeight, scrollHeight } = container;

            // Check if we're near the bottom of the container
            if (scrollTop + clientHeight === scrollHeight && paginationState.last_page !== paginationState.page) {
                fetchCategory({ ...paginationState, page: paginationState.page + 1 });

                setPaginationState((prevState) => ({ ...prevState, page: prevState.page + 1 }));
            }
        }
    }, [paginationState, fetchCategory, setPaginationState]);

    const handleClickDishes = () => {
        showDishesPopup && setShowDishesPopup(!showDishesPopup);
    };

    const handleClickBack = () => {
        showDishesPopup ? handleClickClose() : setShowDishesPopup(!showDishesPopup);
    };

    const isMobileScreen = IsMobileScreen();

    // Only update presetData when checkedCategory changes meaningfully
    useEffect(() => {
        if (checkedCategory.length === 0 && presetData.categories.length === 0) return;
        if (JSON.stringify(checkedCategory) === JSON.stringify(prevCheckedCategoryRef.current)) return;

        const mainCategoryIds = checkedCategory;

        const currentSubcategories = presetData.categories.filter((cat) => {
            return !mainCategoryIds.includes(cat.category_id);
        });

        const mainCategoryEntries = mainCategoryIds.map((categoryId, index) => {
            const existingCategory = presetData.categories.find((cat) => cat.category_id === categoryId);
            return {
                category_id: categoryId,
                sequence: index.toString(),
                items: existingCategory ? existingCategory.items : [],
            };
        });

        const updatedCategories = [...mainCategoryEntries, ...currentSubcategories];

        setPresetData((prev) => ({
            ...prev,
            categories: updatedCategories,
        }));

        prevCheckedCategoryRef.current = [...checkedCategory];
    }, [checkedCategory, presetData.categories]);

    // Update disable status based on checkedCategory
    useEffect(() => {
        setIsDisable(checkedCategory.length === 0);
    }, [checkedCategory]);

    // Initial load of categories
    useEffect(() => {
        fetchCategory({ page: 1, per_page: 10 });
    }, []);

    // Initialize the preset data structure when the component mounts
    useEffect(() => {
        if (!getPresetMenuDetails || Object.keys(getPresetMenuDetails).length === 0 || initialLoadDoneRef.current)
            return;

        console.log('Initializing preset data:', getPresetMenuDetails);

        // Create a structured preset data object
        const initialPresetData = {
            name: getPresetMenuDetails.name,
            categories: getPresetMenuDetails.categories || [],
        };

        setPresetData(initialPresetData);

        // If we're on the Catalogue Preset page, also set the initial checked categories
        if (page === 'Catalogue Preset' && getPresetMenuDetails.categories?.length > 0) {
            const presetCategoryIds = getPresetMenuDetails.categories.map((cat) => cat.category_id);
            setCheckCategory(presetCategoryIds);
        }

        initialLoadDoneRef.current = true;
    }, [getPresetMenuDetails, presetName, page]);

    const setSelectedCategoryDish = useCallback((categoryId) => {
        setSelectedCategoryId(categoryId);
    }, []);

    // Function to update global dish cache - this will be passed to DishSection
    const updateGlobalDishCache = useCallback((categoryId, dishes) => {
        setGlobalDishCache((prev) => ({
            ...prev,
            [categoryId]: dishes,
        }));
    }, []);

    const updateCategoryItems = useCallback((categoryId, items, itemsJSON) => {
        const existingCategoryIndex = presetData.categories.findIndex((cat) => cat.category_id === categoryId);

        if (existingCategoryIndex !== -1) {
            // Update existing category
            const existingCategory = presetData.categories[existingCategoryIndex];
            const existingItemsJSON = JSON.stringify(existingCategory.items || []);

            if (existingItemsJSON === itemsJSON) {
                return;
            }

            // Normalize item sequences
            const normalizedItems = items.map((item, index) => ({
                ...item,
                sequence: index, // Integer sequence starting from 0
            }));

            setPresetData((prev) => {
                const updatedCategories = [...prev.categories];
                updatedCategories[existingCategoryIndex] = {
                    ...existingCategory,
                    items: normalizedItems,
                };
                return { ...prev, categories: updatedCategories };
            });
        } else {
            // Add new subcategory
            if (items.length > 0) {
                // Normalize item sequences
                const normalizedItems = items.map((item, index) => ({
                    ...item,
                    sequence: index,
                }));

                setPresetData((prev) => {
                    return {
                        ...prev,
                        categories: [
                            ...prev.categories,
                            {
                                category_id: categoryId,
                                sequence: prev.categories.length,
                                items: normalizedItems,
                            },
                        ],
                    };
                });
            }
        }
    }, []);

    const handleSavePreset = async () => {
        try {
            // Filter out categories with no items
            let filteredCategories = presetData.categories.filter((cat) => cat.items && cat.items.length > 0);
            // Reset and normalize category sequences starting from 0
            filteredCategories = filteredCategories.map((category, index) => {
                // For each category, also normalize the item sequences
                const normalizedItems = category.items.map((item, itemIndex) => ({
                    ...item,
                    sequence: itemIndex,
                }));

                return {
                    ...category,
                    sequence: index,
                    items: normalizedItems,
                };
            });

            const cleanedPresetData = {
                ...presetData,
                categories: filteredCategories,
            };
            const response = await createPresetMenu(cleanedPresetData);

            if (response.success) {
                handleClickClose();
                // fetchCategory(initialPaginationState, updateFunction);
            }
        } catch (error) {
            console.log('error : ', error);
        }
    };
    const handleEditPreset = async () => {
        try {
            const response = await editPresetMenu();

            if (response.success) {
                handleClickClose();
                // fetchCategory(initialPaginationState, updateFunction);
            }
        } catch (error) {
            console.log('error : ', error);
        }
    };
    return (
        <div
            className={`fixed bg-black bg-opacity-50 inset-0 z-[101] flex py-[18px] md:py-0 md:absolute md:z-[9] md:top-0 md:block ${
                page === 'Outlet menu details' && 'md:relative'
            }`}>
            <div
                className={`max-w-[722px] w-full rounded-xl bg-shades-50 pt-6 pb-6 px-8 md:p-4 m-auto md:m-0 h-full max-h-[991px] md:max-w-full md:max-h-full md:rounded-none md:py-4 md:pb-14 ${
                    page === 'Outlet menu details' && 'md:px-0'
                }`}>
                {!isMobileScreen ? (
                    <div className='flex flex-row items-center justify-between mb-6'>
                        <div>
                            <h3 className='paragraph-large-medium'>Select categories and dishes</h3>
                            <div className='flex flex-row items-center'>
                                <p className='paragraph-medium-italic text-neutral-500'>
                                    Select a set of categories and dishes you would like to include in the preset
                                </p>
                            </div>
                        </div>

                        <span
                            className='cursor-pointer'
                            onClick={handleClickClose}>
                            <CloseIcon />
                        </span>
                    </div>
                ) : (
                    <div
                        className='flex paragraph-medium-medium flex-row mb-4 px-8 md:px-0 cursor-pointer'
                        onClick={handleClickBack}>
                        <LeftArrowIcon className='rotate-90' />
                        <span className='ml-1'>Back to {showDishesPopup ? 'catalogue presets' : 'categories'}</span>
                    </div>
                )}

                {!isMobileScreen && (
                    <div className='mb-[17px]'>
                        <DefaultInputField
                            disabled={isInputFieldDisable}
                            handleClickAddon={() => setIsInputFieldDisable((prevState) => !prevState)}
                            diableInputStyle='text-neutral-900'
                            value={presetData.name}
                            onChange={(value) => setPresetData((prev) => ({ ...prev, name: value }))}
                            addon='Change'
                            boxHeight='[52px]'
                            shadow='shadow-smallDropDownShadow'
                            addonStyle='text-primary-500 paragraph-medium-underline '
                        />
                    </div>
                )}

                <div className='flex flex-row items-start mb-12 pageContent:mb-3 md:flex-col-reverse'>
                    {(showDishesPopup || !isMobileScreen) && (
                        <div
                            ref={containerRef}
                            onScroll={handleScroll}
                            style={{ height: screenSize.height - 326 }}
                            className='overflow-y-auto scrollbar-style w-full -ml-2'>
                            <CategorySection
                                categoriesDetails={categoryList}
                                checkedCategory={checkedCategory}
                                setCheckCategory={setCheckCategory}
                                setSelectedCategoryDish={setSelectedCategoryDish}
                                presetData={presetData}
                                page={page}
                            />
                            {/* {isLoading && (
                                <div className='w-full text-center py-2'>
                                    <p>Loading more categories...</p>
                                </div>
                            )} */}
                        </div>
                    )}
                    <DishSection
                        showDishesPopup={showDishesPopup}
                        isMobileScreen={isMobileScreen}
                        handleClickDishes={handleClickDishes}
                        selectedCategoryId={selectedCategoryId}
                        updateCategoryItems={updateCategoryItems}
                        presetData={presetData}
                        page={page}
                        globalDishCache={globalDishCache}
                        updateGlobalDishCache={updateGlobalDishCache}
                    />
                </div>

                {(page === 'Catalogue' || page === 'Catalogue presets') && (
                    <div className='flex flex-row items-center justify-between w-full'>
                        <span className='paragraph-large-medium'>Step 2/2</span>
                        <div
                            className='max-w-[156px] w-full'
                            onClick={handleSavePreset}>
                            <LargePrimaryButton
                                label='Save as preset'
                                disabled={isDisable}
                            />
                        </div>
                    </div>
                )}

                {(page === 'Outlet menu details' || page === 'Catalogue Preset') && (
                    <div className='flex justify-end mt-12 pageContent:mt-3 md:fixed md:bottom-0 md:block md:w-full md:pb-1 md:pt-2 md:shadow-dropShadow md:bg-white md:-ml-4 md:px-4'>
                        <div className='flex flex-row'>
                            <div
                                className='mr-2 md:w-full'
                                onClick={handleClickClose}>
                                <LargeDestructiveButton label={isMobileScreen ? 'Discard' : 'Discard Changes'} />
                            </div>

                            <div className='ml-2 md:w-full'>
                                <LargePrimaryButton
                                    label={isMobileScreen ? 'Save Changes' : 'Save Changes'}
                                    onClick={handleEditPreset}
                                    disabled={isDisable}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
