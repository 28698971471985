import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Dishes } from '../Components/Catalogue/Components/Card';
import { CheckBoxWithoutLabels } from '../../../Components/FormControl/FormControls';
import { IsMobileScreen } from '../../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';
import { fetchDishList, getFoodType } from '../functions';
import { useSelector } from 'react-redux';
import ListLoader from '../AddonDetails/Components/ListLoader';

export default function DishSection(props) {
    const {
        showDishesPopup,
        isMobileScreen,
        handleClickDishes,
        selectedCategoryId,
        updateCategoryItems,
        presetData,
        page,
        globalDishCache,
        updateGlobalDishCache,
    } = props;

    const containerRef = useRef(null);
    const [checkedDishes, setCheckedDishes] = useState([]);
    const [isHeaderIndeterminate, setIsHeaderIndeterminate] = useState(false);
    const [areAllSelected, setAreAllSelected] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const [dishList, setDishList] = useState([]);
    const isMobilesScreen = IsMobileScreen();
    const screenWidth = useWindowSize().width;
    const screenHeight = useWindowSize().height;

    // Cache for storing fetched dishes by category
    const [dishCache, setDishCache] = useState({});

    // Keep track of all available dish IDs for the selected category
    const [allAvailableDishIds, setAllAvailableDishIds] = useState([]);

    // Keep track of dish IDs grouped by subcategory
    const [dishesBySubcategory, setDishesBySubcategory] = useState({});

    // Ref to prevent redundant updates
    const previousSelectedCategoryIdRef = useRef(null);
    const dishUpdateInProgressRef = useRef(false);

    const getAllSubCategory = useCallback(
        async (category) => {
            // Check if we already have data in cache
            if (dishCache[category.category_id]) {
                return {
                    category: { name: category.internal_name, id: category.category_id },
                    dish: dishCache[category.category_id],
                };
            }

            // Check if we have data in global cache (from parent component)
            if (globalDishCache && globalDishCache[category.category_id]) {
                // Update our local cache
                setDishCache((prev) => ({
                    ...prev,
                    [category.category_id]: globalDishCache[category.category_id],
                }));

                return {
                    category: { name: category.internal_name, id: category.category_id },
                    dish: globalDishCache[category.category_id],
                };
            }

            let dishList = [];

            // Only fetch if not in cache
            try {
                for (let index = 0; index < Math.ceil(category.total_items / 10); index++) {
                    const dish = await fetchDishList(
                        selectedRestaurantId,
                        { page: index + 1, per_page: 10 },
                        category.category_id
                    );
                    if (dish?.data) {
                        dishList.push(...dish.data);
                    }
                }

                // Update both caches
                setDishCache((prev) => ({
                    ...prev,
                    [category.category_id]: dishList,
                }));

                // Update global cache
                if (updateGlobalDishCache) {
                    updateGlobalDishCache(category.category_id, dishList);
                }
            } catch (error) {
                console.error(`Error fetching dishes for category ${category.category_id}:`, error);
            }

            return { category: { name: category.internal_name, id: category.category_id }, dish: dishList };
        },
        [dishCache, globalDishCache, selectedRestaurantId, updateGlobalDishCache]
    );

    const findExistingItems = useCallback(
        (categoryId) => {
            if (!presetData || !presetData.categories) return [];

            // Try to find a direct match in the categories
            let category = presetData.categories.find((cat) => {
                // Handle both string and number comparisons
                return String(cat.category_id) === String(categoryId);
            });

            return category ? category.items || [] : [];
        },
        [presetData]
    );

    // Load dishes when category changes
    useEffect(() => {
        if (!selectedCategoryId) return;

        // Prevent unnecessary reloads if the selectedCategoryId is the same
        if (previousSelectedCategoryIdRef.current?.category_id === selectedCategoryId.category_id) {
            return;
        }

        previousSelectedCategoryIdRef.current = selectedCategoryId;

        setIsLoading(true);
        setCheckedDishes([]);
        setDishesBySubcategory({});

        if (!selectedCategoryId?.sub_categories?.length) {
            setDishList([]);
            setAllAvailableDishIds([]);
            setIsLoading(false);
            return;
        }

        const loadDishes = async () => {
            try {
                const categoryDishes = await Promise.all(
                    selectedCategoryId.sub_categories.map((el) => getAllSubCategory(el))
                );

                setDishList(categoryDishes);

                // Create a map of dish IDs by subcategory
                const dishIdsBySubcategory = {};
                const allDishIds = [];

                categoryDishes.forEach((categoryData) => {
                    if (categoryData.dish && Array.isArray(categoryData.dish)) {
                        const subcategoryId = categoryData.category.id;
                        dishIdsBySubcategory[subcategoryId] = categoryData.dish.map((dish) => dish.item_id);
                        allDishIds.push(...dishIdsBySubcategory[subcategoryId]);
                    }
                });

                setDishesBySubcategory(dishIdsBySubcategory);
                setAllAvailableDishIds(allDishIds);

                // Load selected items from preset data
                if (page === 'Catalogue Preset' && presetData) {
                    const selectedItems = new Set();

                    // Check main category
                    if (selectedCategoryId.category_id) {
                        const mainCategoryItems = findExistingItems(selectedCategoryId.category_id);
                        mainCategoryItems.forEach((item) => {
                            if (allDishIds.includes(item.item_id)) {
                                selectedItems.add(item.item_id);
                            }
                        });
                    }

                    // Check subcategories
                    selectedCategoryId.sub_categories.forEach((subcat) => {
                        const subcatItems = findExistingItems(subcat.category_id);
                        subcatItems.forEach((item) => {
                            if (allDishIds.includes(item.item_id)) {
                                selectedItems.add(item.item_id);
                            }
                        });
                    });

                    if (selectedItems.size > 0) {
                        setCheckedDishes(Array.from(selectedItems));
                    }
                }
            } catch (error) {
                console.error('Error loading dishes:', error);
            } finally {
                setIsLoading(false);
            }
        };

        loadDishes();
    }, [selectedCategoryId, findExistingItems, getAllSubCategory, page, presetData]);

    // Update category items when checked dishes change
    useEffect(() => {
        if (!selectedCategoryId || !updateCategoryItems || dishUpdateInProgressRef.current) return;

        dishUpdateInProgressRef.current = true;

        try {
            if (Object.keys(dishesBySubcategory).length > 0) {
                // Process each subcategory
                Object.entries(dishesBySubcategory).forEach(([subcategoryId, dishIds]) => {
                    const checkedSubcategoryDishes = checkedDishes.filter((id) => dishIds.includes(id));

                    // Convert to number for consistent comparison
                    const subcategoryIdNumber = parseInt(subcategoryId, 10);

                    if (checkedSubcategoryDishes.length > 0) {
                        const sequencedItems = checkedSubcategoryDishes.map((itemId, index) => ({
                            item_id: itemId,
                            sequence: index.toString(),
                        }));

                        updateCategoryItems(subcategoryIdNumber, sequencedItems, JSON.stringify(sequencedItems));
                    } else {
                        updateCategoryItems(subcategoryIdNumber, [], '[]');
                    }
                });
            }
        } finally {
            dishUpdateInProgressRef.current = false;
        }
    }, [checkedDishes, selectedCategoryId, updateCategoryItems, dishesBySubcategory]);

    useEffect(() => {
        const allSelected = allAvailableDishIds?.length > 0 && checkedDishes?.length === allAvailableDishIds?.length;
        const someSelected = checkedDishes?.length > 0 && checkedDishes?.length < allAvailableDishIds?.length;

        setAreAllSelected(allSelected);
        setIsHeaderIndeterminate(someSelected);
    }, [checkedDishes, allAvailableDishIds]);

    const handleSelectAll = () => {
        if (areAllSelected) {
            // Deselect all
            setCheckedDishes([]);
        } else {
            // Select all
            setCheckedDishes([...allAvailableDishIds]);
        }
    };

    const handleDishSelection = (isSelected, dishId) => {
        if (isSelected) {
            if (!checkedDishes.includes(dishId)) {
                setCheckedDishes([...checkedDishes, dishId]);
            }
        } else {
            setCheckedDishes(checkedDishes.filter((id) => id !== dishId));
        }
    };

    // Calculate total dishes count for display
    const totalDishesCount = allAvailableDishIds.length;

    return (
        <>
            <div className='w-full ml-6 md:ml-0'>
                <div
                    className='flex flex-row items-center mb-4 md:cursor-pointer'
                    onClick={handleClickDishes}>
                    <span className='mr-1 text-black paragraph-large-medium'>Dishes</span>
                    <span className='paragraph-small-italic text-neutral-500'>
                        {totalDishesCount > 0
                            ? `Selected - ${checkedDishes.length} of ${totalDishesCount}`
                            : 'No dishes'}
                    </span>
                </div>

                {(!showDishesPopup || !isMobileScreen) && (
                    <div>
                        <div className='flex flex-row items-center mb-[18px]'>
                            <CheckBoxWithoutLabels
                                onChange={handleSelectAll}
                                isChecked={areAllSelected}
                                isIndeterminate={isHeaderIndeterminate}
                            />
                            <span className='paragraph-small-medium ml-2'>Select/deselect all</span>
                        </div>

                        <div
                            ref={containerRef}
                            className='overflow-auto scrollbar-style -mr-8 md:mr-0 md:overflow-hidden md:h-full'
                            style={{
                                height: isMobilesScreen
                                    ? 'auto'
                                    : screenWidth < 1440
                                      ? screenHeight - 376
                                      : screenWidth < 2100
                                        ? screenHeight - 412
                                        : '616px',
                            }}>
                            {isLoading ? (
                                <ListLoader
                                    className='min-w-[20px] h-[70px]'
                                    number={6}
                                />
                            ) : (
                                <>
                                    {dishList.map((categoryData, categoryIndex) => (
                                        <div key={categoryData.category?.id || categoryIndex}>
                                            {categoryData.dish && categoryData.dish.length > 0 && (
                                                <div className='mb-4'>
                                                    <span className='paragraph-small-medium mb-2'>
                                                        {categoryData.category.name}
                                                    </span>
                                                    {categoryData.dish.map((el, index) => {
                                                        const dishId = el.item_id;
                                                        const isChecked = checkedDishes.includes(dishId);
                                                        return (
                                                            <div
                                                                className={`${index !== categoryData.dish.length - 1 && 'mb-2'} pr-3 md:pr-0`}
                                                                key={dishId}>
                                                                <Dishes
                                                                    boxHeight='h-[96px]'
                                                                    title={el.internal_name}
                                                                    variants={el.variants_count}
                                                                    type={getFoodType(el)}
                                                                    image={el.image_url}
                                                                    page='menuManagement'
                                                                    imageSize='h-[74px] w-[74px]'
                                                                    boxPadding='py-2.5'
                                                                    isSelectionActive={true}
                                                                    titleStyle='paragraph-small-medium'
                                                                    isChecked={isChecked}
                                                                    onSelectDish={(isSelected) =>
                                                                        handleDishSelection(isSelected, dishId)
                                                                    }
                                                                    handleClickDish={() =>
                                                                        handleDishSelection(!isChecked, dishId)
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                    {dishList.length === 0 && !isLoading && (
                                        <div className='text-neutral-500 paragraph-small-italic px-3 py-2'>
                                            {selectedCategoryId
                                                ? 'No dishes found in this category.'
                                                : 'Please select a category to view dishes.'}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
