import React from 'react';
import { ReactComponent as CashBackIcon } from '../../../../Assets/loyalty-cashback.svg';
import { TableBlankComponentView } from '../../../../Components/BlankComponent/TableBlankComponentView';

export default function TableSection(props) {
    const { orderDetails } = props;

    if (!orderDetails.length) {
        return (
            <tr>
                <td colSpan={3}>
                    <TableBlankComponentView previewText={'No Content Available'} />
                </td>
            </tr>
        );
    }

    return (
        <>
            {orderDetails.map((el, index) => (
                <tr
                    className='paragraph-small-regular border-t first:border-none even:bg-neutral-50 border-neutral-300 h-[70px] justify-center'
                    key={index}>
                    {el?.customer_name ? <td className='px-6'>{el?.customer_name}</td> : <td className='px-6'>---</td>}
                    {el?.created_at ? <td className='px-6'>{el.created_at}</td> : <td className='px-6'>---</td>}
                    {el?.points ? (
                        <td className='px-6'>
                            <div className='flex flex-row items-center'>
                                <CashBackIcon />
                                <span className='pl-1'>{el.points}</span>
                            </div>
                        </td>
                    ) : (
                        <td className='px-6'>
                            <div className='flex flex-row items-center'>
                                <span className='pl-1'>---</span>
                            </div>
                        </td>
                    )}
                </tr>
            ))}
        </>
    );
}
