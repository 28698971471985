import React, { useCallback, useEffect, useState } from 'react';
import { DefaultInputField } from '../../../../../Components/InputField/InputField';
import _ from 'lodash';
import { ReactComponent as SearchIcon } from '../../../../../Assets/search.svg';
import { ReactComponent as SelectIcon } from '../../../../../Assets/select.svg';
import { ReactComponent as EditIcon } from '../../../../../Assets/edit.svg';
import { ReactComponent as ReArrangeIcon } from '../../../../../Assets/re-arrange.svg';
import { ReactComponent as AddIcon } from '../../../../../Assets/add.svg';
import {
    LargeDestructiveButton,
    LargePrimaryButton,
    LargeTertiaryButton,
} from '../../../../../Components/Buttons/Button';
import ConfirmDeletePopup from '../../../../../Components/ConfirmDeletePopup/ConfirmDeletePopup';
import { useNavigate } from 'react-router-dom';
import { Controller, useFormContext } from 'react-hook-form';
import SearchableDropdown from '../../../../../Components/SearchableDropdown/SearchableDropdown';
import APIV3 from '../../../../../api/axios/APIV3';
import { useSelector } from 'react-redux';

export default function ControllerButton(props) {
    const {
        setPageState,
        pageState,
        setKeyword,
        selectedDishId,
        selectedCategoryId,
        setShowCreatePreset,
        setSelectedItem,
        selectedItem,
    } = props;
    const {
        watch,
        setValue,
        formState: { errors },
        clearErrors,
        setError,
    } = useFormContext();

    const navigate = useNavigate();

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);

    const [isLoadingMenuItems, setIsLoadingMenuItems] = useState(false);

    const [searchedQuery, setSearchedQuery] = useState('');

    const [categoryMenuItems, setCategoryMenuItems] = useState([]);

    const [defaultMenuItems, setDefaultMenuItems] = useState([]);

    const editButton = {
        label: 'Edit',
        onClick: () => setPageState((prevState) => ({ reArrange: false, selection: false, edit: !prevState.edit })),
        leftIconDefault: <EditIcon stroke='#FFFFFF' />,
        leftIconClick: <EditIcon stroke='#C4BEED' />,
    };

    const reArrangeButton = {
        label: 'Re-arrange',
        onClick: () =>
            setPageState((prevState) => ({ edit: false, selection: false, reArrange: !prevState.reArrange })),
        leftIconDefault: <ReArrangeIcon stroke='#FFFFFF' />,
        leftIconClick: <ReArrangeIcon stroke='#C4BEED' />,
    };

    const addOnGroupsButton = {
        label: 'Add-on groups',
        onClick: () => navigate('/menu/addon-details'),
    };

    const createPresetButton = {
        onClick: () => setShowCreatePreset(true),
        label: 'Create preset',
        isDefault: false,
        leftIconDefault: <AddIcon stroke='#FFFFFF' />,
        leftIconClick: <AddIcon stroke='#C4BEED' />,
    };

    const headerButton = [editButton, reArrangeButton, addOnGroupsButton, createPresetButton];

    const handleClickCancelDelete = () => {
        setPageState({
            edit: false,
            reArrange: false,
            selection: false,
        });
    };

    const handleDelete = () => {
        setShowConfirmDeletePopup(false);
        // deleteCategory(selectedRestaurantId);
        // deleteDish(selectedRestaurantId);
    };

    const isButtonClicked = (button) => {
        let isClicked;

        switch (button.label) {
            case 'Edit':
                isClicked = pageState.edit;
                break;

            case 'Re-arrange':
                isClicked = pageState.reArrange;
                break;

            default:
                isClicked = false;
                break;
        }

        return isClicked;
    };

    const formatList = (list) => {
        // Create an array to hold all options (both categories and items)
        let formattedList = [];

        // Add each category and its items
        list.forEach((category) => {
            // Add the category itself
            formattedList.push({
                label: `Category: ${category.internal_name}`,
                value: `category-${category.category_id}`,
                id: category.category_id,
                type: 'category',
                internal_name: category.internal_name,
                isCategory: true, // Flag to identify categories
            });

            // Add each item under this category
            if (category.items && category.items.length > 0) {
                category.items.forEach((item) => {
                    formattedList.push({
                        label: `• ${item.internal_name}`, // Add visual indentation with spaces
                        displayLabel: item.internal_name, // Clean label for display when selected
                        value: `item-${item.item_id}`,
                        id: item.item_id,
                        type: 'item',
                        image: item?.image,
                        is_mapped_item: item?.is_mapped_item,
                        food_type: item?.food_type,
                        show_agmark: item?.show_agmark,
                        category_id: category.category_id,
                        category_name: category.internal_name,
                        internal_name: item.internal_name,
                        variants_count: item.variants_count,
                        isItem: true, // Flag to identify items
                    });
                });
            }
        });

        const selectedIds = watch('selected_category_with_item')?.map((item) => item.value) ?? [];
        const filteredList = formattedList.filter((item) => !selectedIds.includes(item.value));

        setCategoryMenuItems(filteredList);
        !searchedQuery && setDefaultMenuItems(filteredList);
    };
    const fetchCategoryWithItemList = async (params) => {
        try {
            const response = await APIV3.get(
                `restaurants/${selectedRestaurantId}/categories/search?language_id=1&keyword=`,
                {
                    params,
                }
            );

            formatList(response.categories);

            setIsLoadingMenuItems(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelect = (selectedItem, fieldOnChange) => {
        if (!selectedItem) {
            setSelectedItem(null);
            return;
        }
        const currentSelectedCategories = watch('selected_category_with_item') || [];
        const isAlreadySelected = currentSelectedCategories.some((item) => item.value === selectedItem.value) ?? [];

        if (isAlreadySelected) {
            return;
        }

        setSelectedItem(selectedItem);

        const newIdArray = [...currentSelectedCategories, selectedItem];
        setValue('selected_category_with_item', newIdArray);

        fieldOnChange(newIdArray);

        const updateMenuItems = categoryMenuItems.filter((item) => item.value !== selectedItem.value) ?? [];
        setCategoryMenuItems(updateMenuItems);

        clearErrors('selected_category_with_item');
    };

    const onCompleteTyping = (searchQuery) => {
        setSearchedQuery(searchQuery);

        searchQuery.length ? handleFetchList(searchQuery) : setCategoryMenuItems([]);
    };

    const handleFetchList = useCallback(
        _.debounce(async (search_query) => {
            fetchCategoryWithItemList({ keyword: search_query, language_id: 1 });
        }, 3000)
    );

    useEffect(() => {
        fetchCategoryWithItemList({ keyword: '', language_id: 1 });
    }, []);

    const categoryMenuItemToPass = !searchedQuery ? defaultMenuItems : categoryMenuItems;

    return (
        <>
            <div className='flex flex-row justify-between mb-6 md:mb-4 xl:block'>
                <div className='flex flex-row md:mb-1 items-center xl:mb-4'>
                    {pageState.selection ? (
                        <div className='flex flex-row items-center sm:mt-4 md:ml-4 sm:ml-0'>
                            <div
                                className='max-w-[125px] md:max-w-full w-1/2 mr-4 xl:mr-2 cursor-pointer'
                                onClick={handleClickCancelDelete}>
                                <LargeTertiaryButton
                                    isDefault={false}
                                    label='Cancel'
                                />
                            </div>
                            <div
                                className='max-w-[192px] md:max-w-full w-1/2'
                                onClick={() => setShowConfirmDeletePopup(true)}>
                                <LargeDestructiveButton
                                    label='Delete'
                                    isDefault={false}
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className='min-w-[375px] mr-4 w-full pageContentSmall:min-w-[330px] xl:max-w-[298px] pageContentSmall:mr-2 md:max-w-full xl:min-w-0'>
                                <Controller
                                    render={({ field }) => (
                                        <SearchableDropdown
                                            onCompleteTyping={onCompleteTyping}
                                            menuItems={categoryMenuItemToPass}
                                            placeholder='Search category or dish'
                                            handleSelect={(selection) => handleSelect(selection, field.onChange)}
                                            setIsLoadingMenuItems={setIsLoadingMenuItems}
                                            isLoadingMenuItems={isLoadingMenuItems}
                                            labelAsSelectedItem
                                            displayValue={selectedItem?.label}
                                            fixedHeight={categoryMenuItemToPass?.length < 5 ? 'h-fit' : 'h-[200px]'}
                                        />
                                    )}
                                    name='selected_category_with_item'
                                />
                            </div>

                            <div
                                className='max-w-[156px] md:max-w-none md:w-[64px]'
                                onClick={() =>
                                    setPageState((prevState) => ({
                                        edit: false,
                                        reArrange: false,
                                        selection: !prevState.selection,
                                    }))
                                }>
                                <LargePrimaryButton
                                    label='Bulk select'
                                    hideLabel='md:hidden'
                                    leftIconDefault={<SelectIcon stroke='#ffffff' />}
                                    leftIconClick={<SelectIcon stroke='#C4BEED' />}
                                />
                            </div>
                        </>
                    )}
                </div>

                <div className='flex flex-row xl:justify-end md:block md:pt-0 items-center'>
                    {headerButton.map((el, index) => (
                        <div
                            className='ml-4 pageContentSmall:ml-2 md:ml-0 md:inline-block md:w-1/2 even:md:pl-2 md:mt-2 md:align-top cursor-pointer odd:md:pr-2'
                            onClick={el.onClick}
                            key={index}>
                            <LargePrimaryButton
                                label={el.label}
                                leftIconClick={el.leftIconClick}
                                isDefault={el.isDefault}
                                leftIconDefault={el.leftIconDefault}
                                hideLabel='mobile:text-sm'
                                isClicked={isButtonClicked(el)}
                            />
                        </div>
                    ))}
                </div>
            </div>

            {showConfirmDeletePopup && (
                <ConfirmDeletePopup
                    handleClickClose={() => setShowConfirmDeletePopup(false)}
                    description='Would you like to delete selected category and dish?'
                    header='Delete selected category and dish'
                    handleDelete={handleDelete}
                />
            )}
        </>
    );
}
