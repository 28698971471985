import React, { useCallback, useRef, useState } from 'react';
import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { rearrangeCategory } from '../../../../../functions';
import { useWindowSize } from '@uidotdev/usehooks';
import { SortableItem } from '../../../Components/SortableItem';
import CategoryCardSection from '../../../Components/CategoryCardSection';
import SubCategoryCard from '../../../Components/SubCategoryCard';
import HasMoreCard from '../../../Components/HasMoreCard';
import ListLoader from '../../../Components/ListLoader';
import { LargePrimaryButton, LargeTertiaryButton } from '../../../../../../../Components/Buttons/Button';
import { useCategoryContext } from '../../../Context/CategoryContext';

export default function CategoryRearrange(props) {
    const { handleClickClose, setSelectedCategoryDish } = props;

    const {
        fetchCategory,
        paginationState,
        isLoading,
        setPaginationState,
        categoryForRearrange,
        setCategoryForRearrange,
        updateFunction,
    } = useCategoryContext();

    const containerRef = useRef(null);

    const initialPaginationState = { page: 1, per_page: 10, has_more_pages: null };

    const pageState = { edit: false, reArrange: true, selection: false };

    const screenSize = useWindowSize();

    const [showSubCategory, setShowSubCategory] = useState(null);

    const handleScroll = useCallback(() => {
        const container = containerRef.current;

        if (container) {
            const { scrollTop, clientHeight, scrollHeight } = container;

            if (scrollTop + clientHeight === scrollHeight && paginationState.last_page !== paginationState.page) {
                fetchCategory({ ...paginationState, page: paginationState.page + 1 });

                setPaginationState((prevState) => ({ ...prevState, page: prevState.page + 1 }));
            }
        }
    }, [paginationState]);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (!over) {
            return;
        }

        if (active.id !== over.id) {
            setCategoryForRearrange((items) => {
                const newItems = [...items];

                const oldCategoryIndex = newItems.findIndex((item) => item.category_id === active.id);
                const newCategoryIndex = newItems.findIndex((item) => item.category_id === over.id);

                if (oldCategoryIndex !== -1 && newCategoryIndex !== -1) {
                    return arrayMove(newItems, oldCategoryIndex, newCategoryIndex);
                }

                for (let i = 0; i < newItems.length; i++) {
                    const subCategories = newItems[i].sub_categories;
                    const oldSubIndex = subCategories.findIndex((sub) => sub.category_id === active.id);
                    const newSubIndex = subCategories.findIndex((sub) => sub.category_id === over.id);

                    if (oldSubIndex !== -1 && newSubIndex !== -1) {
                        newItems[i].sub_categories = arrayMove(subCategories, oldSubIndex, newSubIndex);
                        break;
                    }
                }

                return newItems;
            });
        }
    };

    const handleDragStart = (event) => {
        const { active } = event;

        if (!!categoryForRearrange.find((el) => el.category_id === active.id)) {
            setShowSubCategory(null);
        }
    };

    const updateReArrange = async () => {
        const payload = {
            categories: categoryForRearrange?.map((el) => ({
                category_id: el.category_id,
                sub_categories: el?.sub_categories?.map((el) => ({ category_id: el.category_id })) ?? [],
            })),
        };

        try {
            const response = await rearrangeCategory(payload);

            if (response.success) {
                handleClickClose();
                fetchCategory(initialPaginationState, updateFunction);
            }
        } catch (error) {
            console.log('error : ', error);
        }
    };

    return (
        <>
            <div
                ref={containerRef}
                onScroll={handleScroll}
                className='overflow-auto scrollbar-style -mr-8 pr-8 mt-4 -ml-4 pl-10'
                style={{ height: screenSize.height - 250 }}>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                    onDragStart={handleDragStart}>
                    <SortableContext items={categoryForRearrange?.map((item) => item.category_id)}>
                        {categoryForRearrange?.map((category) => (
                            <SortableItem
                                key={category.category_id}
                                id={category.category_id}>
                                <CategoryCardSection
                                    pageState={pageState}
                                    category={category}
                                    setShowSubCategory={setShowSubCategory}
                                    showSubCategory={showSubCategory}
                                    setSelectedCategoryDish={setSelectedCategoryDish}
                                />
                                {!!category.sub_categories.length && showSubCategory === category.category_id && (
                                    <SortableContext items={category?.sub_categories?.map((sub) => sub.category_id)}>
                                        <SubCategoryCard
                                            pageState={pageState}
                                            subCategory={category?.sub_categories}
                                            selectedCategoryId={[]}
                                            category={category}
                                        />
                                    </SortableContext>
                                )}
                            </SortableItem>
                        ))}
                    </SortableContext>
                </DndContext>

                {paginationState?.has_more_pages && (
                    <ListLoader
                        className='max-w-[303px] h-[104px] mt-3'
                        number={1}
                    />
                )}

                {isLoading && (
                    <div className='mt-4'>
                        <ListLoader
                            className='max-w-[303px] h-[104px]'
                            number={6}
                        />
                    </div>
                )}
            </div>

            <div className='flex flex-row items-center gap-2 absolute w-full max-w-[442px] pt-4 pb-4 -ml-8 pl-4 bg-white bottom-0'>
                <div
                    className='w-1/2'
                    onClick={handleClickClose}>
                    <LargeTertiaryButton label='Cancel' />
                </div>
                <div
                    className='w-1/2'
                    onClick={updateReArrange}>
                    <LargePrimaryButton label='Save' />
                </div>
            </div>
        </>
    );
}
