import React, { useEffect, useState } from 'react';
import DetailsDropDown from '../Components/DetailsDropDown';
import CategoryDetails from '../Components/CategoryDetails';
import DishDetailsSection from '../Components/DishDetailsSection';

export default function DetailsSection(props) {
    const { selectedCategoryDish, setDishOperationState, clickedCategory } = props;

    const detailMenuItem = [
        { label: 'Dish details', value: 'dishDetails' },
        { label: 'Category details', value: 'categoryDetails' },
    ];

    const [activeDetails, setActiveDetails] = useState(detailMenuItem?.[1]);

    useEffect(() => {
        if (selectedCategoryDish?.clickedDish) {
            setActiveDetails(detailMenuItem[0]);
        } else {
            setActiveDetails(detailMenuItem[1]);
        }
    }, [selectedCategoryDish?.clickedDish]);

    return (
        <>
            <div className='flex flex-row justify-between items-center w-full pt-0.5 mb-4 md:mb-2 md:pt-0'>
                <span className='paragraph-large-medium'>View details</span>
                <DetailsDropDown
                    handleChangeDropDown={(item) => setActiveDetails(item)}
                    menuItem={detailMenuItem}
                    selectedItem={activeDetails}
                />
            </div>

            {activeDetails?.value === 'dishDetails' ? (
                <DishDetailsSection selectedCategoryDish={selectedCategoryDish} />
            ) : (
                <CategoryDetails
                    selectedCategoryDish={selectedCategoryDish}
                    setDishOperationState={setDishOperationState}
                    clickedCategory={clickedCategory}
                />
            )}
        </>
    );
}
