import { Fragment, useEffect, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { ReactComponent as DownArrowIcon } from '../../Assets/chevron-down.svg';
import { MenuItemsLoader } from '../ContentLoader/DropdownLoader';

export default function SearchableDropdown(props) {
    const {
        menuItems,
        placeholder,
        openFromBottom,
        fixedHeight,
        handleSelect,
        isLoadingMenuItems,
        setIsLoadingMenuItems,
        labelAsSelectedItem,
        zIndex,
        disabled,
    } = props;

    const [displayValue, setDisplayValue] = useState('');

    useEffect(() => {
        props.displayValue && setDisplayValue(props.displayValue);
    }, [props.displayValue]);

    const handleChange = (value) => {
        if (!value) {
            setDisplayValue('');
            handleSelect(null);
            return;
        }
        handleSelect(value);

        if (!labelAsSelectedItem) {
            onCompleteTyping('');
            return;
        }

        setDisplayValue(value.label);
    };

    const onCompleteTyping = (searchedText) => {
        setDisplayValue(searchedText);
        setIsLoadingMenuItems?.(!!searchedText.length);
        props.onCompleteTyping(searchedText.toString());
    };

    return (
        <div className=''>
            <Combobox
                value={displayValue}
                onChange={handleChange}
                disabled={disabled}>
                <div className='relative'>
                    <Combobox.Button className='relative w-full cursor-default overflow-hidden rounded-md bg-white text-left shadow-Xsmall focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm'>
                        <Combobox.Input
                            className='w-full flex flex-row items-center justify-between mobile:max-w-full rounded-md outline-none border py-3 appearance-none border-neutral-300 pl-4 pr-10 placeholder:text-neutral-300'
                            placeholder={placeholder}
                            displayValue={displayValue}
                            onChange={(event) => onCompleteTyping(event.target.value)}
                        />

                        <span className='absolute inset-y-0 right-0 flex items-center pr-2'>
                            <DownArrowIcon
                                className='dropDownIconRotate'
                                height={24}
                                width={24}
                                fill='#131126'
                            />
                        </span>
                    </Combobox.Button>

                    <Transition
                        as={Fragment}
                        leave='transition ease-in duration-100'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'>
                        <Combobox.Options
                            autoFocus={true}
                            className={`absolute left-0 right-0 mt-2 px-4 py-2 border paragraph-small-medium rounded-md shadow-medium bg-shades-50 font-normal outline-none ${
                                openFromBottom && 'bottom-full mb-2'
                            } ${fixedHeight && fixedHeight + ' overflow-auto scrollbar-style'} ${zIndex ?? 'z-50'} ${disabled ? 'hidden' : ''}`}>
                            {isLoadingMenuItems ? (
                                <MenuItemsLoader />
                            ) : !menuItems?.length ? (
                                <div className='relative cursor-default select-none px-1 py-2 paragraph-small-regular text-neutral-700'>
                                    Nothing found.
                                </div>
                            ) : (
                                menuItems.map((el, index) => (
                                    <Combobox.Option
                                        key={index}
                                        className={({ active }) =>
                                            `paragraph-small-regular ml-1 pt-2 mb-2 ${
                                                active &&
                                                'text-primary-500 cursor-pointer active:paragraph-small-semi-bold active:text-primary-500'
                                            }`
                                        }
                                        value={el}>
                                        {el.label}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </div>
    );
}
