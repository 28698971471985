import React, { useEffect, useState } from 'react';
import ListViewOfferLink from './ListViewOfferLink';
import ToggleSwitch from '../../../../../Components/ToggleSwitch/ToggleSwitch';
import { IsMobileScreen } from '../../../../../Constants/Constants';
import TableLoader from '../../../../../Components/ContentLoader/TableLoader';
import { TableBlankComponentView } from '../../../../../Components/BlankComponent/TableBlankComponentView';
import { useSelector } from 'react-redux';

export default function LinkOutletTable(props) {
    const {
        setOutletsToUpdate,
        isOutletsLoading,
        isFilterLoading,
        selectedPresetForLink,
        availableOutlets,
        selectedLangaugeId,
    } = props;
    const isMobileScreen = IsMobileScreen();

    const [linkedOutlets, setLinkedOutlets] = useState([]);

    useEffect(() => {
        if (
            selectedPresetForLink &&
            selectedPresetForLink.menu_outlets &&
            selectedPresetForLink.menu_outlets.length > 0
        ) {
            const activeOutlets = selectedPresetForLink.menu_outlets
                .filter((outlet) => outlet?.is_active === 1)
                .map((outlet) => outlet.outlet_id);
            setLinkedOutlets(activeOutlets);
        }
    }, [selectedPresetForLink]);

    const getIsEnable = (outletId) => {
        return linkedOutlets.includes(outletId);
    };

    const handleChangeLinking = async (doLink, outletId) => {
        // Update linked outlets state
        setLinkedOutlets((prevLinkedOutlets) => {
            const updatedLinkedOutlets = doLink
                ? [...prevLinkedOutlets, outletId]
                : prevLinkedOutlets.filter((id) => id !== outletId);
            return updatedLinkedOutlets;
        });

        // Create a map of outlet IDs that already exist in menu_outlets
        const existingOutletMap = {};
        if (selectedPresetForLink && selectedPresetForLink.menu_outlets) {
            selectedPresetForLink.menu_outlets.forEach((outlet) => {
                existingOutletMap[outlet.outlet_id] = outlet.is_active;
            });
        }

        setOutletsToUpdate((prevUpdates) => {
            // Create copies of the previous arrays to avoid mutation
            let linkingIds = [...prevUpdates.linking_outlets_ids];
            let unlinkingIds = [...prevUpdates.unlinking_outlets_ids];

            if (doLink) {
                if (existingOutletMap[outletId] === 0) {
                    linkingIds = [...linkingIds, outletId];
                    unlinkingIds = unlinkingIds.filter((id) => id !== outletId);
                } else if (!existingOutletMap.hasOwnProperty(outletId)) {
                    linkingIds = [...linkingIds, outletId];
                }
            } else {
                if (existingOutletMap[outletId] === 1) {
                    unlinkingIds = [...unlinkingIds, outletId];
                    linkingIds = linkingIds.filter((id) => id !== outletId);
                } else if (linkingIds.includes(outletId)) {
                    linkingIds = linkingIds.filter((id) => id !== outletId);
                }
            }

            return {
                linking_outlets_ids: linkingIds,
                unlinking_outlets_ids: unlinkingIds,
            };
        });
    };
    const formatAddress = (item) => {
        const state = item?.outletData?.outlet_address.translations.state[selectedLangaugeId];
        const city = item?.outletData?.outlet_address.translations.city[selectedLangaugeId];
        const address = `${item.outletData.outlet_address.translations.address_line_1[selectedLangaugeId]}, ${item.outletData.outlet_address.translations.address_line_2[selectedLangaugeId]}`;
        return { state, city, address };
    };
    return (
        <>
            {!isMobileScreen ? (
                <div className='w-full rounded-lg overflow-auto [&::-webkit-scrollbar]:hidden border-neutral-300 border'>
                    <table className='w-full break-words table-row-bg'>
                        <thead>
                            <tr className='paragraph-overline-small text-neutral-700 shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                                <th className='px-6 min-w-[90px]'>STATUS</th>
                                <th className='px-6 min-w-[302px]'>OUTLET NAME</th>
                                <th className='px-6 min-w-[102px] lg:min-w-[135px]'>STATE</th>
                                <th className='px-6 min-w-[92px] lg:min-w-[151px]'>CITY</th>
                                <th className='px-6 min-w-[334px]'>ADDRESS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isOutletsLoading || isFilterLoading ? (
                                <tr>
                                    <td colSpan={5}>
                                        <TableLoader
                                            hasSwitch={true}
                                            columns={5}
                                        />
                                    </td>
                                </tr>
                            ) : availableOutlets && availableOutlets.length > 0 ? (
                                availableOutlets.map((outlet, index) => {
                                    const { state, city, address } = formatAddress(outlet);
                                    const isEnabled = getIsEnable(outlet?.outletId);
                                    return (
                                        <tr
                                            className='paragraph-small-regular border-t first:border-none justify-center h-[70px]'
                                            key={index}>
                                            <td className='px-6'>
                                                <ToggleSwitch
                                                    canChange={true}
                                                    isEnable={isEnabled}
                                                    setIsEnabled={(value) =>
                                                        handleChangeLinking(value, outlet.outletId)
                                                    }
                                                />
                                            </td>
                                            <td className='px-6'>{outlet?.displayName}</td>
                                            <td className='px-6'>{state}</td>
                                            <td className='px-6'>{city}</td>
                                            <td className='px-6'>{address}</td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={5}>
                                        <TableBlankComponentView previewText={'No Outlets Available'} />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className=''>
                    {isOutletsLoading || isFilterLoading ? (
                        <TableLoader
                            hasSwitch={true}
                            columns={1}
                        />
                    ) : availableOutlets && availableOutlets?.length > 0 ? (
                        availableOutlets.map((outlet, index) => {
                            const isEnabled = getIsEnable(outlet.outletId);
                            const { state, city, address } = formatAddress(outlet);
                            return (
                                <div
                                    className='mt-2     '
                                    key={index}>
                                    <ListViewOfferLink
                                        id={outlet?.outletId}
                                        displayName={outlet?.displayName}
                                        state={state}
                                        city={city}
                                        address={address}
                                        handleChangeLinking={(value) => handleChangeLinking(value, outlet?.outletId)}
                                        isEnable={isEnabled}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <TableBlankComponentView previewText={'No Outlets Available'} />
                    )}
                </div>
            )}
        </>
    );
}
