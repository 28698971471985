import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../../../Assets/close.svg';
import { ReactComponent as FilterIcon } from '../../../../../Assets/filter.svg';
import { ReactComponent as LeftArrowIcon } from '../../../../../Assets/chevron-down.svg';
import { ReactComponent as SearchIcon } from '../../../../../Assets/search.svg';
import { DefaultInputField } from '../../../../../Components/InputField/InputField';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../../../Components/Buttons/Button';
import LinkOutletTable from './LinkOutletTable';
import { IsMobileScreen } from '../../../../../Constants/Constants';
import { editToggleOutlet } from '../../../functions';
import AlreadyLinkPopup from './AlreadyLinkPopup';
import DropdownWithValueLabel from '../../../../../Components/DropDown/DropdownWithValueLabel';
import { useSelector } from 'react-redux';

export default function LinkOutletPopup(props) {
    const {
        handleClickClose,
        headerBottomLine,
        selectedPresetForLink,
        selectedRestaurantId,
        fetchOPresetList,
        isOutletsLoading,
    } = props;

    const selectedLangaugeId = useSelector((state) => state.langauge.languageId);
    const availableOutlets = useSelector((state) => state.outlet.outletList);
    const isMobileScreen = IsMobileScreen();

    // State for outlet updates and errors
    const [outletsToUpdate, setOutletsToUpdate] = useState({
        linking_outlets_ids: [],
        unlinking_outlets_ids: [],
    });
    const [apiError, setApiError] = useState(null);
    const [showErrorPopup, setShowErrorPopup] = useState(false);

    // Filter states
    const [showFilters, setShowFilters] = useState(false);
    const [isFilterLoading, setIsFilterLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [selectedFilter, setSelectedFilter] = useState({ label: 'All', value: 'all' });
    const [selectedState, setSelectedState] = useState({ label: 'All', value: 'all' });
    const [availableStates, setAvailableStates] = useState([]);

    // Debounce the search term
    useEffect(() => {
        setIsFilterLoading(true);

        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
            setIsFilterLoading(false);
        }, 300);

        return () => {
            clearTimeout(handler);
            setIsFilterLoading(false);
        };
    }, [searchTerm]);

    // Get available states from outlets
    useEffect(() => {
        if (!availableOutlets) return;

        const states = availableOutlets.reduce((result, outlet) => {
            const stateName = outlet.outletData?.outlet_address.translations.state[selectedLangaugeId];
            if (stateName && !result.includes(stateName)) {
                result.push(stateName);
            }
            return result;
        }, []);

        // Format and sort states for dropdown
        const formattedStates = states
            .filter(Boolean)
            .sort()
            .map((state) => ({ label: state, value: state }));

        // Add the "All" option at the beginning of the array
        setAvailableStates([{ label: 'All', value: 'all' }, ...formattedStates]);
    }, [availableOutlets, selectedLangaugeId]);

    // Handle saving changes
    const handleSaveChanges = async () => {
        try {
            if (outletsToUpdate.linking_outlets_ids.length > 0 || outletsToUpdate.unlinking_outlets_ids.length > 0) {
                await editToggleOutlet(
                    selectedRestaurantId,
                    selectedPresetForLink.id,
                    outletsToUpdate.linking_outlets_ids,
                    outletsToUpdate.unlinking_outlets_ids
                );
                setOutletsToUpdate({ linking_outlets_ids: [], unlinking_outlets_ids: [] });
                handleClickClose();
                fetchOPresetList();
            }
        } catch (error) {
            console.error('Error updating outlets:', error);
            if (error.response && error?.response?.data) {
                setApiError(error?.response?.data?.errors);
                setShowErrorPopup(true);
            } else {
                setApiError({ message: 'An unexpected error occurred.' });
                setShowErrorPopup(true);
            }
        }
    };

    // Close error popup
    const closeErrorPopup = () => {
        setShowErrorPopup(false);
        setApiError(null);
    };

    // Outlet status filter options
    const outletStatus = [
        { label: 'All', value: 'all' },
        { label: 'Linked', value: 'linked' },
        { label: 'Unlinked', value: 'unlinked' },
    ];

    // Toggle filter visibility
    const handleClickFilter = () => {
        setShowFilters(!showFilters);
    };

    // Handle filter selection
    const handleSelection = async (value) => {
        setIsFilterLoading(true);
        try {
            await new Promise((resolve) => setTimeout(resolve, 300));
            setSelectedFilter(value);
        } finally {
            setIsFilterLoading(false);
        }
    };

    // Handle state selection
    const handleStateSelection = async (state) => {
        setIsFilterLoading(true);
        try {
            await new Promise((resolve) => setTimeout(resolve, 300));
            setSelectedState(state);
        } finally {
            setIsFilterLoading(false);
        }
    };

    // Get filtered outlets based on selected criteria
    const getFilteredOutlets = () => {
        if (!availableOutlets || !selectedPresetForLink || !selectedPresetForLink.menu_outlets) return [];

        // Create a map of outlet status
        const outletStatusMap = {};
        selectedPresetForLink.menu_outlets.forEach((outlet) => {
            outletStatusMap[outlet.outlet_id] = outlet.is_active;
        });

        // Start with all outlets
        let filteredOutlets = [...availableOutlets];

        // Apply search filter if debounced search term exists
        if (debouncedSearchTerm) {
            filteredOutlets = filteredOutlets.filter((outlet) =>
                outlet.displayName?.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
            );
        }

        // Apply outlet status filter
        switch (selectedFilter?.value) {
            case 'linked':
                filteredOutlets = filteredOutlets.filter((outlet) => outletStatusMap[outlet.outletId] === 1);
                break;
            case 'unlinked':
                filteredOutlets = filteredOutlets.filter(
                    (outlet) =>
                        outletStatusMap[outlet.outletId] === 0 || !outletStatusMap.hasOwnProperty(outlet.outletId)
                );
                break;
            default:
                // Keep all outlets
                break;
        }

        // Apply state filter only if "All" is not selected
        if (selectedState && selectedState.value !== 'all') {
            filteredOutlets = filteredOutlets.filter((outlet) => {
                const outletState = outlet.outletData?.outlet_address.translations.state[selectedLangaugeId];
                return outletState === selectedState.value;
            });
        }

        return filteredOutlets;
    };

    // Get filtered outlets
    const filteredOutletLists = getFilteredOutlets();

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center items-center overflow-auto md:bg-white md:absolute p-4 md:py-0'>
                <div className='max-w-[997px] w-full rounded-xl md:rounded-none bg-shades-50 px-8 py-6 md:px-4 md:pt-4 m-auto md:max-w-full lg:max-w-[710px] md:absolute md:top-0 md:pb-24'>
                    {isMobileScreen && (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                            onClick={() => handleClickClose()}>
                            <LeftArrowIcon className='rotate-90' />
                            <span className='paragraph-medium-medium ml-1'>Back to catalogue preset</span>
                        </div>
                    )}
                    {!isMobileScreen && (
                        <div className='flex flex-row justify-between items-center mb-2 pb-4'>
                            <div>
                                <span className='paragraph-large-medium'>Link outlets</span>
                                <div className='flex flex-row items-center'>
                                    <span className='font-normal italic text-base leading-6 text-neutral-500'>
                                        {headerBottomLine}
                                    </span>
                                    <div className='w-1 h-1 rounded-full mx-3 bg-neutral-500' />
                                    <span className='paragraph-medium-italic text-neutral-500'>
                                        Outlet selected - {filteredOutletLists.length}
                                    </span>
                                </div>
                            </div>
                            <div
                                className='cursor-pointer'
                                onClick={() => handleClickClose()}>
                                <CloseIcon />
                            </div>
                        </div>
                    )}

                    <div className='flex flex-row justify-between mb-6 md:block md:mb-4'>
                        <div className='max-w-[375px] w-full lg:max-w-[298px] md:max-w-full md:mb-4'>
                            <DefaultInputField
                                placeholderIcon={<SearchIcon stroke='#D3D2D8' />}
                                placeholder='Search outlet'
                                enteredValue={setSearchTerm}
                            />
                        </div>
                        <div className='flex flex-row justify-end w-full md:w-auto'>
                            {showFilters ? (
                                <div className='-mt-6 flex flex-row w-full justify-end mr-3 md:mt-3 md:justify-start'>
                                    <div className='w-full max-w-[200px] mr-3'>
                                        <DropdownWithValueLabel
                                            menuItems={availableStates}
                                            selectedItem={selectedState}
                                            setSelected={(selection) => handleStateSelection(selection)}
                                            placeholder='Select State'
                                            label='State'
                                        />
                                    </div>

                                    <div className='w-full max-w-[200px]'>
                                        <DropdownWithValueLabel
                                            menuItems={outletStatus}
                                            selectedItem={selectedFilter}
                                            placeholder='Select Outlet'
                                            label='Outlets'
                                            setSelected={(selection) => handleSelection(selection)}
                                        />
                                    </div>
                                </div>
                            ) : null}
                            <div
                                className='flex flex-row items-center md:justify-between'
                                onClick={handleClickFilter}>
                                <LargePrimaryButton
                                    label='Filters'
                                    leftIconDefault={<FilterIcon fill='#FFFFFF' />}
                                    leftIconClick={<FilterIcon fill='#C4BEED' />}
                                />
                            </div>
                        </div>
                    </div>

                    <LinkOutletTable
                        selectedLangaugeId={selectedLangaugeId}
                        selectedPresetForLink={selectedPresetForLink}
                        isFilterLoading={isFilterLoading}
                        isOutletsLoading={isOutletsLoading}
                        availableOutlets={filteredOutletLists}
                        setOutletsToUpdate={setOutletsToUpdate}
                    />

                    {showErrorPopup && apiError && (
                        <AlreadyLinkPopup
                            errorMessage={apiError}
                            availableOutlets={filteredOutletLists}
                            onClose={closeErrorPopup}
                        />
                    )}

                    <div className='flex justify-end mt-12 md:fixed md:bottom-0 md:justify-center md:block md:w-full md:pb-1 md:pt-2 md:shadow-dropShadow md:bg-white md:-ml-4 md:px-4'>
                        <div className='flex flex-row'>
                            <div
                                className='mr-2 md:w-full'
                                onClick={() => handleClickClose()}>
                                <LargeDestructiveButton
                                    label='Discard changes'
                                    fontsSize='sm:text-[15px]'
                                />
                            </div>
                            <div
                                className='ml-2 md:w-full'
                                onClick={handleSaveChanges}>
                                <LargePrimaryButton
                                    label='Save changes'
                                    fontsSize='sm:text-[15px]'
                                    isClicked={showErrorPopup ? false : {}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
