import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as Close } from '../../../Assets/close.svg';
import { ReactComponent as CalenderIcon } from '../../../Assets/calendar.svg';
import ToggleSwitch from '../../../Components/ToggleSwitch/ToggleSwitch';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { RadioButton } from '../../../Components/FormControl/FormControls';
import { IsMobileScreen } from '../../../Constants/Constants';
import { useSelector } from 'react-redux';
import CustomTime from './CustomTime';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

export default function EditCategoryPopup(props) {
    const {
        handleClickClose,
        editType,
        onSave,
        hidden,
        setHidden,
        handleCategoryItemEdit,
        success,
        timeTypeName,
        selectedTimeName,
        selectedDateName,
        selectedOption,
        setSelectedOption,
        modalResponse,
        positionClicked,
    } = props;

    const orderingModes = useSelector((state) => state.outlet.orderingModes);
    const methods = useFormContext();
    const {
        watch,
        setValue,
        control,
        formState: { isDirty },
        clearErrors,
        errors,
    } = methods;
    const [isShowCalender, SetIsShowCalender] = useState(false);
    const [initialSelectedOption, setInitialSelectedOption] = useState(null);
    const [initialHidden, setInitialHidden] = useState(false);
    const [afterSaveButton, setAfterSaveButton] = useState(false);

    const handleOptionChange = (value) => {
        setSelectedOption(value);
        value === 'radioBox04' ? SetIsShowCalender(true) : SetIsShowCalender(false);
    };

    const isMobileScreen = IsMobileScreen();

    const initializeSwitchStates = useCallback(() => {
        const initialStates = {};

        // Check if in_stock_ordering_mode is equal to total_ordering_modes
        if (
            modalResponse?.in_stock_ordering_mode !== 0 &&
            modalResponse?.total_ordering_modes !== 0 &&
            modalResponse?.in_stock_ordering_mode === modalResponse?.total_ordering_modes
        ) {
            // If true, set all ordering modes to true
            orderingModes.forEach((mode) => {
                initialStates[mode.id] = true;
            });
        } else if (modalResponse?.in_stock_ordering_mode === 0) {
            // If in_stock_ordering_mode or total_ordering_modes is 0, set all ordering modes to false
            orderingModes.forEach((mode) => {
                initialStates[mode.id] = false;
            });
        } else {
            // Otherwise, proceed with the existing logic
            if (modalResponse?.outlet_ordering_modes?.length) {
                // Create a map to store the stock status for each restaurant_ordering_mode_id
                const stockStatusMap = {};

                modalResponse.outlet_ordering_modes.forEach((mode) => {
                    // If there are multiple entries for same restaurant_ordering_mode_id,
                    // take the most recent one (assuming array is ordered)
                    stockStatusMap[mode.restaurant_ordering_mode_id] = mode.stock;
                });

                // Initialize states based on orderingModes and their stock status
                orderingModes.forEach((mode) => {
                    // Check if we have a stock status for this mode.id
                    initialStates[mode.id] = Boolean(stockStatusMap[mode.id]);
                });
            } else {
                // Fallback to all false if no modal response
                orderingModes.forEach((mode) => {
                    initialStates[mode.id] = false;
                });
            }
        }

        return initialStates;
    }, [modalResponse, orderingModes]);

    const [switchStates, setSwitchStates] = useState(() => initializeSwitchStates());

    useEffect(() => {
        setSwitchStates(initializeSwitchStates());
        setInitialSelectedOption(selectedOption);
        setInitialHidden(hidden);
    }, [modalResponse, orderingModes, initializeSwitchStates]);

    const handleSwitchChange = (name, value) => {
        setSwitchStates((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setAfterSaveButton(true);
    };

    const getSwitchDisabled = (switchValue) => {
        return (positionClicked === 'right' && switchValue) || (positionClicked === 'left' && !switchValue);
    };

    const hasChanges = useCallback(() => {
        const initialStates = initializeSwitchStates();
        const selectedOptionChanged = selectedOption !== initialSelectedOption;
        const hiddenChanged = hidden !== initialHidden;
        const switchStatesChanged = Object.keys(switchStates).some((key) => switchStates[key] !== initialStates[key]);
        const timeChanged =
            selectedOption === 'radioBox04' &&
            (methods.formState.isDirty ||
                methods.watch('scheduleNotification')?.dateTime?.date !== '' ||
                methods.watch('scheduleNotification')?.dateTime?.time !== '');

        return selectedOptionChanged || hiddenChanged || switchStatesChanged || timeChanged;
    }, [switchStates, initializeSwitchStates, selectedOption, initialSelectedOption, hidden, initialHidden, methods]);

    const [clicked, setClicked] = useState(false);

    const handleSave = () => {
        if (typeof onSave === 'function') {
            onSave(switchStates);
        }
    };

    useEffect(() => {
        if (clicked) handleSave();
    }, [clicked]);

    useEffect(() => {
        if (success === 'true') {
            props.handleClickClose();
        }
    }, [success]);

    const scheduleNotificationMenuItems = [{ label: 'Custom date & time', value: 'custom' }];

    return (
        <FormProvider {...methods}>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 p-4 overflow-auto flex'>
                <div className='max-w-[475px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:w-full md:px-4 md:py-4'>
                    <div className='flex flex-row justify-between items-center mb-6 md:mb-[11px]'>
                        <div>
                            <span className='paragraph-large-medium'>Edit category availability</span>
                            <div className='flex flex-row items-center'>
                                <span className='paragraph-medium-italic text-neutral-500'>
                                    Toggle on availability for following modes
                                </span>
                            </div>
                        </div>
                        <div
                            onClick={handleClickClose}
                            className='md:hidden cursor-pointer'>
                            <Close />
                        </div>
                    </div>

                    {editType !== 'left' && (
                        <>
                            <div className='pb-6 mb-6 border-b border-neutral-300 md:mb-4 md:pb-4'>
                                <div className='mb-4'>
                                    <span className='paragraph-small-semi-bold text-black'>
                                        Auto turn-on dish after
                                        <span className='paragraph-x-small-italic text-neutral-500 ml-1'>
                                            (Out of stock will be displayed)
                                        </span>
                                    </span>
                                </div>
                                <div>
                                    <RadioButton
                                        marginB='2'
                                        label='2 hours'
                                        optionId='radioBox01'
                                        value='2 hours'
                                        checked={selectedOption === 'radioBox01'}
                                        handleOptionChange={() => handleOptionChange('radioBox01')}
                                    />
                                    <RadioButton
                                        marginB='2'
                                        label='4 hours'
                                        optionId='radioBox02'
                                        value='4 hours'
                                        checked={selectedOption === 'radioBox02'}
                                        handleOptionChange={() => handleOptionChange('radioBox02')}
                                    />
                                    <RadioButton
                                        marginB='2'
                                        label='Next business days'
                                        optionId='radioBox03'
                                        value='Next business days'
                                        checked={selectedOption === 'radioBox03'}
                                        handleOptionChange={() => handleOptionChange('radioBox03')}
                                    />
                                    <RadioButton
                                        marginB='2'
                                        label='Schedule time to reopen'
                                        optionId='radioBox04'
                                        value='Schedule time to reopen'
                                        checked={selectedOption === 'radioBox04'}
                                        handleOptionChange={() => handleOptionChange('radioBox04')}
                                    />
                                    {isShowCalender && (
                                        <div className='mb-4'>
                                            <CustomTime
                                                label='Schedule Manually'
                                                menuItems={scheduleNotificationMenuItems}
                                                watch={watch}
                                                timeTypeName={timeTypeName}
                                                control={control}
                                                selectedTimeName={selectedTimeName}
                                                selectedDateName={selectedDateName}
                                                setValue={setValue}
                                                pageTitle={'pageTitle'}
                                                errors={errors}
                                            />
                                        </div>
                                    )}
                                    <RadioButton
                                        label='Manually turn it on'
                                        optionId='radioBox05'
                                        value='Manually turn it on'
                                        checked={selectedOption === 'radioBox05'}
                                        handleOptionChange={() => handleOptionChange('radioBox05')}
                                    />
                                </div>
                            </div>

                            <div
                                className='mb-6 pb-6 border-b border-neutral-300 flex flex-row justify-between md:mb-4 md:pb-4'
                                onClick={() => setHidden(!hidden)}>
                                <span className='text-black paragraph-small-semi-bold'>
                                    Make this dish invisible too
                                </span>
                                <ToggleSwitch
                                    isEnable={hidden}
                                    setIsEnabled={(value) => setHidden(value)}
                                />
                            </div>
                        </>
                    )}

                    <div className='mb-20 md:mb-4'>
                        <div className='paragraph-small-semi-bold text-black mb-4'>
                            <span>Status</span>
                            <span className='ml-4'>Ordering mode</span>
                        </div>
                        {orderingModes?.map((mode) => (
                            <div
                                onClick={() => setClicked(!clicked)}
                                key={mode.id}
                                className='paragraph-small-regular flex flex-row items-center mb-4 md:mb-[14px]'>
                                <ToggleSwitch
                                    isEnable={switchStates[mode.id]}
                                    setIsEnabled={(value) => handleSwitchChange(mode.id, value)}
                                    disabled={getSwitchDisabled(switchStates[mode.id])}
                                    afterSaveButton={afterSaveButton}
                                />
                                <span className='ml-[21px]'>{mode.display_name}</span>
                            </div>
                        ))}
                    </div>

                    <div
                        className='md:flex md:flex-row md:justify-between'
                        onClick={() => {
                            handleCategoryItemEdit();
                            setAfterSaveButton(true);
                        }}>
                        {isMobileScreen && (
                            <div className='w-full mr-[15px]'>
                                <button
                                    onClick={handleClickClose}
                                    className='h-12 w-full paragraph-medium-medium text-neutral-500 border border-neutral-300 rounded-md lg:max-w-[289px] cursor-pointer'>
                                    Cancel
                                </button>
                            </div>
                        )}
                        <LargePrimaryButton
                            label='Save'
                            onClick={handleSave}
                            // disabled={!hasChanges()}
                        />
                    </div>
                </div>
            </div>
        </FormProvider>
    );
}
