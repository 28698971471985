import React from 'react';

const OptionButtonSwitch = ({ position, handleChangeAvailability, setPositionClicked }) => {
    return (
        <div className='flex h-6 w-[52px] rounded-[20px] relative'>
            <div className='sr-only sm:not-sr-only'>
                <input
                    type='radio'
                    name='switchPosition'
                    checked={position === 'left'}
                    onClick={() => handleChangeAvailability()}
                />
                <input
                    type='radio'
                    name='switchPosition'
                    checked={position === 'middle'}
                    readOnly
                />
                <input
                    type='radio'
                    name='switchPosition'
                    checked={position === 'right'}
                    onClick={() => handleChangeAvailability()}
                />
            </div>

            <div
                className={`absolute inset-0 w-full h-full rounded-[20px] ${
                    position === 'left'
                        ? 'bg-destructive-500'
                        : position === 'middle'
                          ? 'bg-secondary-700'
                          : 'bg-primary-500'
                }`}>
                <label
                    className='absolute left-0 w-1/3 h-full cursor-pointer'
                    onClick={() => {
                        handleChangeAvailability();
                        setPositionClicked('left');
                    }}
                />
                <div className='absolute left-1/3 w-1/3 h-full' />
                <label
                    className='absolute right-0 w-1/3 h-full cursor-pointer'
                    onClick={() => {
                        handleChangeAvailability();
                        setPositionClicked('right');
                    }}
                />
                <div
                    className={`h-5 w-5 bg-white absolute rounded-full top-0.5 transition-all duration-200 ${
                        position === 'left' ? 'left-0.5' : position === 'middle' ? 'left-[16px]' : 'right-0.5'
                    }`}
                    onClick={position === 'middle' ? undefined : () => handleChangeAvailability()}
                />
            </div>
        </div>
    );
};

export default OptionButtonSwitch;
