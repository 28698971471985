import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as MenuIcon } from '../../../../../Assets/menu.svg';
import ListViewCataloguePresets from './ListViewCataloguePresets';
import { Link } from '../../../../../Components/LinkOffer/Link';
import { IsMobileScreen } from '../../../../../Constants/Constants';
import TableLoader from '../../../../../Components/ContentLoader/TableLoader';
import { TableBlankComponentView } from '../../../../../Components/BlankComponent/TableBlankComponentView';
import { CheckBoxWithoutLabels } from '../../../../../Components/FormControl/FormControls';

export default function PresetDetails(props) {
    const {
        handleClickPreset,
        handleClickLinkOutlet,
        handleClickCreatedBy,
        handleCheckboxChange,
        setPresetDetail,
        presetDetail,
        setIsLoading,
        isLoading,
        checkedItems,
        setCheckedItems,
        showCheckbox,
    } = props;

    const isMobileScreen = IsMobileScreen();
    const [isHeaderIndeterminate, setIsHeaderIndeterminate] = useState(false);
    const areAllCurrentPageItemsSelected = useMemo(() => {
        const currentPageIds = presetDetail?.map((notification) => notification.id);
        const selectedCurrentPageItems = currentPageIds?.filter((id) => checkedItems?.includes(id));

        setIsHeaderIndeterminate(
            selectedCurrentPageItems?.length > 0 && selectedCurrentPageItems?.length < currentPageIds?.length
        );

        return currentPageIds?.length > 0 && selectedCurrentPageItems?.length === currentPageIds?.length;
    }, [presetDetail, checkedItems]);

    const selectAll = () => {
        const currentPageIds = presetDetail?.map((notification) => notification.id);

        if (areAllCurrentPageItemsSelected) {
            const newCheckedItems = checkedItems?.filter((id) => !currentPageIds?.includes(id));
            setCheckedItems(newCheckedItems);
        } else {
            const newIds = currentPageIds.filter((id) => !checkedItems.includes(id));
            setCheckedItems([...checkedItems, ...newIds]);
        }
    };

    return (
        <>
            {!isMobileScreen ? (
                <div className='mb-4'>
                    <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                        <table className='w-full break-words table-row-bg'>
                            <thead>
                                <tr className='shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                                    <th className='px-6 min-w-[273px] lg:min-w-[250px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        <div className='flex flex-row'>
                                            {showCheckbox && (
                                                <div className='mr-4 cursor-pointer'>
                                                    <CheckBoxWithoutLabels
                                                        onChange={selectAll}
                                                        isChecked={areAllCurrentPageItemsSelected}
                                                        isIndeterminate={isHeaderIndeterminate}
                                                    />
                                                </div>
                                            )}
                                            <span className='mt-0.5'>PRESET NAME</span>
                                        </div>
                                    </th>
                                    <th className='px-6 min-w-[256px] lg:min-w-[237px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        CATEGORIES AND DISHES
                                    </th>
                                    <th className='px-6 min-w-[199px] lg:min-w-[162px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        CREATED BY
                                    </th>
                                    <th className='px-6 min-w-[179px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        CREATED ON
                                    </th>
                                    <th className='px-6 min-w-[179px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        LAST UPDATE
                                    </th>
                                    <th className='px-6 min-w-[184px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        LINK OUTLET
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <tr>
                                        <td colSpan={6}>
                                            <TableLoader
                                                hasSwitch={false}
                                                columns={6}
                                            />
                                        </td>
                                    </tr>
                                ) : presetDetail && presetDetail.length > 0 ? (
                                    presetDetail.map((el, index) => {
                                        const totalActiveOutlets =
                                            el.menu_outlets?.reduce(
                                                (acc, outlet) => acc + (outlet.is_active ? 1 : 0),
                                                0
                                            ) || 0;

                                        return (
                                            <tr
                                                className='paragraph-small-regular border-t first:border-none border-neutral-300 justify-center h-[70px]'
                                                key={index}>
                                                <td className='px-6 cursor-pointer'>
                                                    <div className='flex flex-row items-center'>
                                                        {showCheckbox && (
                                                            <div className='mr-4 cursor-pointer'>
                                                                <CheckBoxWithoutLabels
                                                                    onChange={(isChecked) => {
                                                                        handleCheckboxChange(el.id, isChecked);
                                                                    }}
                                                                    isChecked={checkedItems.includes(el.id)}
                                                                />
                                                            </div>
                                                        )}
                                                        <span
                                                            className='cursor-pointer text-primary-500 hover:underline underline-offset-3'
                                                            onClick={() => handleClickPreset(el.id)}>
                                                            {el.name}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className='px-6'>
                                                    <div className='flex flex-col'>
                                                        <div className='flex flex-row items-center mb-1'>
                                                            <MenuIcon />
                                                            <span className='paragraph-small-regular ml-2'>
                                                                Categories - {el.menu_catagories_count}
                                                            </span>
                                                        </div>
                                                        <div className='flex flex-row items-center'>
                                                            <MenuIcon />
                                                            <span className='paragraph-small-regular ml-2'>
                                                                Dishes - {el.menu_items_count}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td
                                                    className='px-6 text-primary-500 cursor-pointer'
                                                    onClick={handleClickCreatedBy}>
                                                    {el.user?.name}
                                                </td>
                                                <td className='px-6'>{el.created_at_date}</td>
                                                <td className='px-6'>{el.updated_at_date}</td>
                                                <td className='px-6'>
                                                    <div
                                                        className='cursor-pointer'
                                                        onClick={() => handleClickLinkOutlet(el)}>
                                                        <Link linkOfferNumber={totalActiveOutlets} />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={6}>
                                            <TableBlankComponentView previewText={'No Content Available'} />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div className=''>
                    {isLoading ? (
                        <TableLoader
                            hasSwitch={false}
                            columns={1}
                        />
                    ) : presetDetail && presetDetail.length > 0 ? (
                        presetDetail.map((el, index) => {
                            const totalActiveOutlets =
                                el.menu_outlets?.reduce((acc, outlet) => acc + (outlet.is_active ? 1 : 0), 0) || 0;
                            return (
                                <div
                                    className='mt-2'
                                    key={index}>
                                    <ListViewCataloguePresets
                                        presetName={el.name}
                                        categories={el.menu_catagories_count}
                                        dishes={el.menu_items_count}
                                        createdBy={el.user?.name}
                                        createdOn={el.created_at_date}
                                        lastUpdatedOn={el.updated_at_date}
                                        totalActiveOutlets={totalActiveOutlets}
                                        handleClickViewDetails={handleClickPreset}
                                        handleLClickLink={handleClickLinkOutlet}
                                        handleClickCreatedBy={handleClickCreatedBy}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <TableBlankComponentView previewText={'No Content Available'} />
                    )}
                </div>
            )}
        </>
    );
}
