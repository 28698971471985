import React, { useEffect, useState } from 'react';
import { Switch } from '@headlessui/react';

export default function ToggleSwitch(props) {
    const { setIsEnabled, cursorType, canChange, disabled, afterSaveButton } = props;

    const [isEnable, setIsEnable] = useState(props.isEnable ?? false);

    useEffect(() => {
        setIsEnable(props?.isEnable);
    }, [props.isEnable]);

    const handleChange = (value) => {
        if (disabled) return;
        setIsEnabled && setIsEnabled(value);
        canChange && setIsEnable(value);
    };

    return (
        <div className='max-h-6'>
            <Switch
                onChange={handleChange}
                checked={isEnable}
                // disabled={afterSaveButton === 'true' ? disabled : null}
                disabled={disabled}
                className={`${isEnable ? 'bg-primary-500' : 'bg-neutral-300'} relative inline-flex h-6 w-10 shrink-0 ${
                    cursorType ?? 'cursor-pointer'
                } rounded-xl border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}>
                <span
                    aria-hidden='true'
                    className={`${
                        isEnable ? 'translate-x-4' : 'translate-x-0'
                    } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
            </Switch>
        </div>
    );
}
