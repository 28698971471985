import { getFoodType } from '../../../functions';
import { Dishes } from './Card';

export const DraggableDishItem = (props) => {
    const {
        handleClickEditCategoryDish,
        pageState,
        item,
        setSelectedCategoryDish,
        selectedCategoryDish,
        handleUnlinkItem,
        selectedDishId = [],
        setSelectedDishId,
    } = props;

    const handleSelectDish = (isSelected) => {
        const selectedDishState = isSelected
            ? [...selectedDishId, item.item_id]
            : selectedDishId.filter((el) => el !== item.item_id);

        setSelectedDishId?.(selectedDishState);
    };

    return (
        <div className={`mt-4 min-w-[411px] lg:min-w-0 ${(pageState.edit || pageState.reArrange) && 'md:pl-2.5'}`}>
            <Dishes
                handleClickEdit={() => handleClickEditCategoryDish?.()}
                isEdit={pageState.edit}
                title={item.internal_name}
                image={item.image_url}
                variants={item.variants_count}
                handleClickDish={() =>
                    setSelectedCategoryDish?.((prevState) => ({
                        ...prevState,
                        dish: item.item_id,
                        clickedDish: item.item_id,
                    }))
                }
                isActive={selectedCategoryDish?.dish === item.item_id}
                isRearrange={pageState.reArrange}
                type={getFoodType(item)}
                showUnlink={!!item.is_mapped_item}
                handleUnlinkItem={handleUnlinkItem}
                isSelectionActive={pageState.selection}
                onSelectDish={(isSelected) => handleSelectDish(isSelected)}
            />
        </div>
    );
};
