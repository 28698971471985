import React, { useEffect, useState } from 'react';
import { IsMobileScreen } from '../../../../Constants/Constants';
import TableSection from './TableSection';
import ListViewSection from './ListViewSection';
import PaginationWithNumber from '../../../../Components/Pagination/PaginationWithNumber';
import { useNavigate } from 'react-router-dom';
import TableLoader from '../../../../Components/ContentLoader/TableLoader';
import ListViewLoader from '../../../../Components/ContentLoader/ListViewLoader';
import _ from 'lodash';

export default function ListSection(props) {
    const {
        selectedDates,
        updatePaginationState,
        paginationState,
        isLoading,
        orderDetails,
        handlePagination,
        fetchOrderDetail,
    } = props;

    const isMobileScreen = IsMobileScreen();

    const [paginationChanged, setPaginationChanged] = useState(false);

    useEffect(() => {
        setPaginationChanged(true);
        const timer = setTimeout(() => {
            setPaginationChanged(false);
        }, 500);
        return () => clearTimeout(timer);
    }, [paginationState]);

    return (
        <>
            {!isMobileScreen ? (
                <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border mt-4'>
                    <table className='w-full break-words'>
                        <thead>
                            <tr className='bg-neutral-50 text-left paragraph-overline-small text-neutral-700 shadow-innerShadow h-11 justify-center'>
                                <th className='px-6 min-w-[230px] lg:min-w-[185px]'>CUSTOMER NAME</th>
                                <th className='px-6 min-w-[230px] lg:min-w-[163px]'>SIGN-UP DATE</th>
                                <th className='px-6 min-w-[225px] lg:min-w-[200px]'>CASHBACK EARNED</th>
                            </tr>
                        </thead>

                        <tbody>
                            {isLoading || paginationChanged ? (
                                <tr>
                                    <td
                                        colSpan={6}
                                        className='p-0'>
                                        <TableLoader
                                            hasSwitch={false}
                                            columns={6}
                                        />
                                    </td>
                                </tr>
                            ) : (
                                <TableSection orderDetails={orderDetails} />
                            )}
                        </tbody>
                    </table>
                </div>
            ) : isLoading || paginationChanged ? (
                <div>
                    {[...Array(10)].map((_, index) => (
                        <ListViewLoader
                            key={index}
                            hasSwitch={false}
                        />
                    ))}
                </div>
            ) : (
                <ListViewSection
                    orderDetails={orderDetails}
                    paginationState={paginationState}
                    fetchOrderDetail={fetchOrderDetail}
                />
            )}

            {!_.isEmpty(orderDetails) ? (
                <div className='mt-4'>
                    <PaginationWithNumber
                        lastPage={paginationState.lastPage}
                        selectedPage={paginationState.selectedPage}
                        setSelectedPage={(value) => {
                            handlePagination({ selectedPage: value }, 'selectedPage');
                        }}
                        setResultPerPage={(value) => handlePagination({ resultPerPage: value }, 'resultPerPage')}
                        selectedResultPerpage={paginationState.resultPerPage}
                        isLoading={isLoading}
                    />
                </div>
            ) : null}
        </>
    );
}
