import React from 'react';
import { ReactComponent as SearchIcon } from '../../../../../Assets/search.svg';
import { ReactComponent as SelectIcon } from '../../../../../Assets/select.svg';
import { ReactComponent as CloseIcon } from '../../../../../Assets/close.svg';
import { useSelector } from 'react-redux';
import { DefaultInputField } from '../../../../../Components/InputField/InputField';
import {
    LargeDestructiveButton,
    LargePrimaryButton,
    LargeTertiaryButton,
} from '../../../../../Components/Buttons/Button';
import APIV3 from '../../../../../api/axios/APIV3';

export default function HeaderCataloguePreset(props) {
    const {
        showCancelDelete,
        handleClickCancel,
        isDisable,
        checkedItems,
        fetchOPresetList,
        handleBulkSelect,
        setSearchedPreset,
        setPopup,
        setDeleteApiError,
        setIsDeleted,
    } = props;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const handleClickDelete = async () => {
        try {
            const response = await APIV3.delete(`restaurants/${selectedRestaurantId}/menus`, {
                params: {
                    menu_ids: checkedItems,
                },
            });
            if (response?.success) {
                fetchOPresetList();
                setIsDeleted(true);
            } else {
                setPopup(true);
                setDeleteApiError(response.failed_menus); // Access response.data.failed_menus
            }
        } catch (error) {
            console.error('API Error:', error); // Improved error logging
            setPopup(true); // Still show the popup
        }
    };

    return (
        <>
            <section className='flex flex-row items-center justify-between mb-6 md:block md:mb-4'>
                <div className='flex flex-row items-center md:mb-4'>
                    <div className='w-full min-w-[375px] lg:min-w-[298px] lg:mr-2 md:min-w-0'>
                        <DefaultInputField
                            shadow='shadow-xSmall'
                            placeholder='Search presets'
                            placeholderIcon={<SearchIcon stroke='#D3D2D8' />}
                            enteredValue={setSearchedPreset}
                        />
                    </div>

                    {showCancelDelete ? (
                        <div className='flex flex-row items-center md:w-full'>
                            <div
                                className='max-w-[120px] w-full ml-4 lg:ml-2 md:max-w-[56px] mobile:md:max-w-[45px]'
                                onClick={handleClickCancel}>
                                <LargeTertiaryButton
                                    isDefault={false}
                                    label='Cancel'
                                    hideLabel='lg:hidden'
                                    leftIconDefault={<CloseIcon stroke='#FFFFFF' />}
                                    leftIconClick={<CloseIcon stroke='#C4BEED' />}
                                />
                            </div>
                            <div
                                className='max-w-[200px] w-full ml-4 lg:ml-2 md:max-w-[56px] mobile:md:max-w-[45px]'
                                onClick={handleClickDelete}>
                                <LargeDestructiveButton
                                    label='Delete'
                                    leftIconDefault={<SelectIcon stroke='#FFFFFF' />}
                                    leftIconClick={<SelectIcon stroke='#D7EDEB' />}
                                    hideLabel='lg:hidden'
                                    disabled={isDisable}
                                />
                            </div>
                        </div>
                    ) : (
                        <div
                            className='max-w-[156px] w-full md:max-w-[64px] ml-4 lg:ml-2 md:ml-1'
                            onClick={handleBulkSelect}>
                            <LargePrimaryButton
                                leftIconDefault={<SelectIcon stroke='#ffffff' />}
                                leftIconClick={<SelectIcon stroke='#C4BEED' />}
                                label='Bulk select'
                                hideLabel='lg:hidden'
                            />
                        </div>
                    )}
                </div>
            </section>
        </>
    );
}
