import React, { useCallback, useEffect, useRef, useState } from 'react';
import GroupCard from '../../../Cards/GroupCard';
import { fetchAddonList } from '../../../../../functions';
import { useWindowSize } from '@uidotdev/usehooks';
import _ from 'lodash';
import ListLoader from '../../../ListLoader';

export default function CardSection(props) {
    const { selectedAddon, setSelectedAddon } = props;

    const [addonList, setAddonList] = useState([]);

    const getAddonList = async (params) => {
        try {
            const response = await fetchAddonList(params);

            params.page === 1
                ? setAddonList(response.data)
                : setAddonList((prevState) => [...prevState, ...response.data]);

            _.isEmpty(selectedAddon) && setSelectedAddon(response.data[0]);
        } catch (error) {
            console.log(`error : `, error);
        }
    };

    const [paginationState, setPaginationState] = useState({ page: 1, per_page: 10, has_more_pages: null });

    useEffect(() => {
        getAddonList(paginationState);
    }, []);

    const containerRef = useRef(null);

    const { height: screenHeight } = useWindowSize();

    const handleScroll = useCallback(() => {
        const container = containerRef.current;

        if (container) {
            const { scrollTop, clientHeight, scrollHeight } = container;

            if (scrollTop + clientHeight === scrollHeight && paginationState.last_page !== paginationState.page) {
                getAddonList({ ...paginationState, page: paginationState.page + 1 });

                setPaginationState((prevState) => ({ ...prevState, page: prevState.page + 1 }));
            }
        }
    }, [paginationState]);

    return (
        <>
            {addonList.length ? (
                <div
                    ref={containerRef}
                    onScroll={handleScroll}
                    className='overflow-auto scrollbar-style -mr-8 pr-8 -ml-10 pl-10 space-y-2'
                    style={{ height: screenHeight - 300 }}>
                    {addonList?.map((el, index) => {
                        return (
                            <GroupCard
                                key={index}
                                name={el.internal_name}
                                choiceNumber={el.choices?.length}
                                handleClickCard={() => setSelectedAddon(el)}
                                isSelected={selectedAddon?.addon_group_id === el.addon_group_id}
                            />
                        );
                    })}
                </div>
            ) : (
                <ListLoader number={6} />
            )}
        </>
    );
}
