import React, { useState } from 'react';
import { ReactComponent as SearchIcon } from '../../../Assets/search.svg';
import { ReactComponent as FilterIcon } from '../../../Assets/filter.svg';
import { ReactComponent as SelectIcon } from '../../../Assets/select.svg';
import { Tab } from '../../../Components/Tabs/Tabs';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { DefaultInputField } from '../../../Components/InputField/InputField';
import { DropDownTabs } from '../../../Components/DropDown/DropDownTabs';
import { IsMobileScreen } from '../../../Constants/Constants';
import DropdownWithValueLabel from '../../../Components/DropDown/DropdownWithValueLabel';
import { useParams } from 'react-router-dom';

const Header = (props) => {
    const {
        handleClickAvailabilityTab,
        showItemAvailability,
        showDishMobile,
        setKeyword,
        dishesAddOnsOff,
        setSelectedFilter,
    } = props;

    const { tab } = useParams();

    const availabilityTab = [
        { label: 'Dish availability', badgeText: `Off-${dishesAddOnsOff.dishes ?? '---'}` },
        { label: 'Add-on availability', badgeText: `Off-${dishesAddOnsOff['add-ons'] ?? '---'}` },
    ];

    const isMobileScreen = IsMobileScreen();

    const [showFilters, setShowFilters] = useState(false);

    const itemsStatus = [
        { label: 'All Items', value: 'all_items' },
        { label: 'Out of stock', value: 'out_of_stock' },
    ];

    const handleShowFilter = () => {
        setShowFilters(!showFilters);
    };

    const handleSelection = (selection) => {
        setSelectedFilter(selection?.value);
    };

    return (
        <>
            {!isMobileScreen ? (
                <div className='flex flex-row mb-4 pb-4 border-b border-neutral-300'>
                    {availabilityTab.map((el, index) => (
                        <div
                            onClick={() => handleClickAvailabilityTab(index === 0 ? 'dish' : 'add-on')}
                            className='mr-2 w-fit cursor-pointer'
                            key={index}>
                            <Tab
                                {...el}
                                isActive={index === showItemAvailability}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                !showDishMobile && (
                    <div className='pb-4 border-b mb-4'>
                        <DropDownTabs
                            menuItems={[
                                {
                                    item: 'Dish availability',
                                    badgeText: 'Off - 10',
                                    onClick: () => handleClickAvailabilityTab('dish'),
                                },
                                {
                                    item: 'Add-on availability',
                                    badgeText: 'Off - 10',
                                    onClick: () => handleClickAvailabilityTab('add-on'),
                                },
                            ]}
                        />
                    </div>
                )
            )}

            {!(showDishMobile && isMobileScreen) && (
                <div className='flex flex-row justify-between mb-6 md:block lg:mb-4'>
                    <div className='flex flex-row md:mb-3'>
                        <div className='min-w-[375px] lg:min-w-[298px] w-full mr-4 md:mr-2 md:min-w-0'>
                            <DefaultInputField
                                placeholder='Search addon group or dishes'
                                placeholderIcon={<SearchIcon stroke='#D3D2D8' />}
                                enteredValue={(e) => setKeyword(e)}
                            />
                        </div>
                    </div>

                    <div onClick={handleShowFilter}>
                        <LargePrimaryButton
                            label='Filters'
                            leftIconDefault={<FilterIcon fill='#FFFFFF' />}
                            leftIconClick={<FilterIcon fill='#C4BEED' />}
                        />
                    </div>
                </div>
            )}
            {showFilters ? (
                tab === 'dish' ? (
                    <div className='flex sm:block items-center justify-end mt-4 mb-4 md:mb-4'>
                        <div className='flex mobile:block'>
                            <div className='w-[200px] sm:w-1/2 mobile:w-full mobile:mr-0 mobile:mb-3'>
                                <DropdownWithValueLabel
                                    menuItems={itemsStatus}
                                    selectedItem={itemsStatus[0]}
                                    placeholder='Select Item Type'
                                    setSelected={(selection) => handleSelection(selection)}
                                    label='Dish Type'
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='flex sm:block items-center justify-end mt-4 mb-4 md:mb-4'>
                        <div className='flex mobile:block'>
                            <div className='w-[200px] sm:w-1/2 mobile:w-full mobile:mr-0 mobile:mb-3'>
                                <DropdownWithValueLabel
                                    menuItems={itemsStatus}
                                    selectedItem={itemsStatus[0]}
                                    placeholder='Select Add-on Type'
                                    setSelected={(selection) => handleSelection(selection)}
                                    label='Add-on Type'
                                />
                            </div>
                        </div>
                    </div>
                )
            ) : null}
        </>
    );
};

export default Header;
