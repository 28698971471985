import React, { useEffect, useRef, useState } from 'react';
import { LargePrimaryButton } from '../../Components/Buttons/Button';
import { ReactComponent as RiderIcon } from '../../Assets/riders.svg';
import { ReactComponent as OrderIcon } from '../../Assets/order.svg';
import { ReactComponent as DistanceIcon } from '../../Assets/distance.svg';
import { ReactComponent as CashIcon } from '../../Assets/cash.svg';
import { ReactComponent as OrderRatingIcon } from '../../Assets/order-ratings.svg';
import { ReactComponent as TimerIcon } from '../../Assets/timer.svg';
import { ReactComponent as CashCollection } from '../../Assets/cash-collection.svg';
import PaginationNumber from '../../Components/Pagination/PaginationWithNumber';
import ListViewBillDetails from './Components/ListViewRiderDetail';
import SliderDashboard from 'react-slick';
import { useNavigate, useParams } from 'react-router-dom';
import { IsMobileScreen } from '../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';
import CardDescription from '../../Components/Card/CardDescription';
import Card from '../../Components/Card/Card';
import RatingGuide from '../../Components/RatingGuide/RatingGuide';
import Header from './Components/Header';
import RiderDetailsTop from './Components/RiderDetailsTop';
import OrderDetailsPopup from '../../Components/CustomerOrderDetails/OrderDetailsPopup';
import APIV5 from '../../api/axios/APIV5';
import { format, differenceInDays } from 'date-fns';
import TableLoader from '../../Components/ContentLoader/TableLoader';
import ListViewLoader from '../../Components/ContentLoader/ListViewLoader';
import { TableBlankComponentView } from '../../Components/BlankComponent/TableBlankComponentView';
import { ListBlankComponentView } from '../../Components/BlankComponent/ListBlankComponentView';
import CardContentLoader from '../../Components/ContentLoader/CardContentLoader';
import _ from 'lodash';
import { useSelector } from 'react-redux';

export default function RiderDetails() {
    const navigate = useNavigate();
    const { id } = useParams();

    const today = new Date();
    const screenHeight = useWindowSize().height;
    const isMobileScreen = IsMobileScreen();

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const [paginationState, setPaginationState] = useState({ resultPerPage: 10, selectedPage: 1, lastPage: 1 });
    const [riderDetails, setRiderDetails] = useState({});
    const [riderOrderDetails, setRiderOrderDetails] = useState({});
    const [selectedDates, setSelectedDates] = useState([today, today]);
    const [isLoading, setIsloading] = useState(false);
    const [isRiderTopLoading, setIsRiderTopLoading] = useState(false);
    const [isRiderOrderFilter, setIsRiderOrderFilter] = useState(null);
    const [appliedRiderOrderFilter, setAppliedRiderOrderFilter] = useState(null);
    const [riderCardDetails, setRiderCardDetails] = useState([]);
    const [isLoadingCards, setIsLoadingCards] = useState(false);
    const [isInitialRender, setIsInitialRender] = useState(true);
    const [appliedFilter, setAppliedFilter] = useState(null);
    const [updatedpaginationStatus, setUpdatedpaginationStatus] = useState({});
    const [ratingArray, setRatingArray] = useState([]);
    const [popupDetails, setPopupDetails] = useState({});
    const [isPopupLoading, setIsPopupLoading] = useState(false);
    const [paginationChanged, setPaginationChanged] = useState(false);
    const [showTotalSalesCard, setShowTotalSalesCard] = useState(false);

    const formattedDate = (dateString) => {
        return dateString ? format(new Date(dateString), 'dd MMM yyyy') : 'N/A';
    };

    const formatDate = (date) => format(date, 'yyyy-MM-dd');

    function formatDeliveryTime(deliveryTime) {
        if (!deliveryTime) return '0 sec';

        const [hours, minutes, seconds] = deliveryTime?.split(':').map((part) => parseInt(part, 10));
        const formattedTime = [];

        if (hours > 0) {
            if (hours > 1) {
                formattedTime.push(`${hours} hrs`);
            } else {
                formattedTime.push(`${hours} hr`);
            }
        }

        if (minutes > 0) {
            formattedTime.push(`${minutes} mins`);
        }

        if (hours === 0 && minutes === 0 && seconds > 0) {
            formattedTime.push(`${seconds} sec`);
        }

        return formattedTime.length ? formattedTime.join(' ') : '0 sec';
    }

    function formattDated(dateString) {
        const [day, month, year] = dateString.split('-').map(Number);
        const date = new Date(year, month - 1, day);
        const monthNames = ['Jan', 'Feb', 'March', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        const monthName = monthNames[date.getMonth()];
        const formattedDate = `${day} ${monthName} ${year}`;

        return formattedDate;
    }

    const goToRiderPage = () => {
        navigate('/rider');
    };

    const fetchRiderDetail = async () => {
        setIsRiderTopLoading(true);
        try {
            const response = await APIV5.get(
                `restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/riders/${id}`
            );
            setRiderDetails(response.rider_details);
            setIsRiderTopLoading(false);
            setIsRiderOrderFilter(true);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    const fetchRiderOrderCardDetails = async (params) => {
        try {
            setIsLoadingCards(true);
            const response = await APIV5.get(
                `restaurants/${selectedRestaurantId}/riders/${id}/rider-orders-total-details`,
                {
                    params: { ...params, ...defaultParams() },
                }
            );

            setRiderCardDetails(response?.statistics);
            setIsLoadingCards(false);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    const defaultParams = () => {
        const [from_date, to_date] = selectedDates.sort((a, b) => a - b);
        return {
            rating: { from: ratingArray[0]?.toFixed(1), to: ratingArray[1]?.toFixed(1) },
            payment_mode: appliedRiderOrderFilter,
            filters: {
                from_date: formatDate(from_date),
                to_date: formatDate(to_date),
            },
        };
    };

    const fetchRiderOrderDetails = async (params) => {
        try {
            setIsloading(true);
            const defaultParamsData = defaultParams();
            const response = await APIV5.get(`restaurants/${selectedRestaurantId}/riders/${id}/orders`, {
                params: {
                    ...params,
                    ...defaultParamsData,
                    per_page: params.per_page ?? updatedpaginationStatus.resultPerPage,
                    page: params.page ?? updatedpaginationStatus.selectedPage,
                },
            });

            setRiderOrderDetails(response?.rider_order_details.data);
            setPaginationState((prevState) => {
                return {
                    ...prevState,
                    lastPage: response.rider_order_details.last_page,
                    selectedPage: response.rider_order_details.current_page,
                };
            });
            setIsloading(false);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    const updatePaginationState = (updates) => {
        setUpdatedpaginationStatus(updates);
        const newSelectedPage =
            updates.selectedPage !== undefined ? updates.selectedPage : paginationState.selectedPage;
        const params = {
            page: newSelectedPage,
            per_page: paginationState.resultPerPage,
            payment_mode: appliedRiderOrderFilter,
            rating: ratingArray,
        };

        fetchRiderDetail(params, () => {
            if (newSelectedPage === paginationState.selectedPage) {
                setPaginationState((prevState) => ({
                    ...prevState,
                    per_page: updates.resultPerPage ?? paginationState.resultPerPage,
                    selectedPage: newSelectedPage,
                    ...updates,
                }));
            }
        });
    };

    useEffect(() => {
        switch (appliedFilter) {
            case 'bad':
                setRatingArray([0.0, 2.9]);
                break;
            case 'average':
                setRatingArray([3.0, 3.9]);
                break;
            case 'good':
                setRatingArray([4.0, 5.0]);
                break;
            case null:
                setRatingArray([0.0, 5.0]);
                break;
            default:
                setRatingArray([]);
                break;
        }
    }, [appliedFilter]);

    const handlePagination = (data, type) => {
        if (type === 'selectedPage') {
            updatePaginationState(data);
            setPaginationState((prevState) => ({ ...prevState, ...data }));
            return;
        }

        updatePaginationState({ ...data, selectedPage: 1 });
        setPaginationState((prevState) => ({ ...prevState, ...data, selectedPage: 1 }));
    };

    useEffect(() => {
        setIsLoadingCards(true);
        setIsRiderTopLoading(true);
        fetchRiderDetail();
        fetchRiderOrderCardDetails();
    }, [id, selectedDates]);

    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
            return;
        }
        setIsloading(true);
        const params = { rating: ratingArray, payment_mode: appliedFilter };

        fetchRiderOrderDetails(params);
    }, [selectedDates, appliedRiderOrderFilter, ratingArray, updatedpaginationStatus]);

    useEffect(() => {
        setPaginationChanged(true);
        const timer = setTimeout(() => {
            setPaginationChanged(false);
        }, 500);
        return () => clearTimeout(timer);
    }, [paginationState]);

    const handleClickTotalSalesCard = () => {
        setShowTotalSalesCard(!showTotalSalesCard);
    };

    const settingsDashboardSlider = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: 'dashboard-slide',
    };

    function orderingModes(mode) {
        switch (mode) {
            case 'cash_on_delivery':
                return 'Cash on delivery';
            case 'online':
                return 'Online';
            default:
                return 'Unknown ordering mode';
        }
    }
    const cardDetails = [
        {
            title: 'Total orders delivered',
            highlightedTitle:
                riderCardDetails && riderCardDetails?.total_orders_delivered
                    ? riderCardDetails?.total_orders_delivered
                    : 'N/A',
            description: <CardDescription description={`Number of orders delivered by the rider for selected dates`} />,
            icon: <OrderIcon stroke='#ffffff' />,
        },
        {
            title: 'Total distance travelled',
            description: <CardDescription description={`Total distance travelled by the rider for selected dates`} />,
            highlightedTitle:
                riderCardDetails && riderCardDetails?.total_distance_travelled
                    ? `${riderCardDetails?.total_distance_travelled} kms`
                    : 'N/A',
            reimbursement: 'Total distance travelled by the rider this week',
            icon: <DistanceIcon stroke='#ffffff' />,
        },
        {
            title: 'Total amount earned',
            highlightedTitle:
                riderCardDetails && riderCardDetails?.total_amount_earned
                    ? `₹${riderCardDetails?.total_amount_earned}/-`
                    : 'N/A',
            description: <CardDescription description={`Total amount earned by the rider for selected dates`} />,
            icon: <CashIcon stroke='#ffffff' />,
        },
        {
            title: 'Average rider rating',
            ratingCard: true,
            highlightedTitle:
                riderCardDetails && riderCardDetails?.average_rating ? riderCardDetails?.average_rating : 0,
            description: (
                <CardDescription
                    description={`Rider rating received was :focusContent: by ${
                        riderCardDetails && riderCardDetails?.feedback_count ? riderCardDetails?.feedback_count : '0'
                    } customers for selected dates`}
                    focusContent={
                        riderCardDetails && riderCardDetails.average_rating >= 4
                            ? 'good'
                            : riderCardDetails.average_rating >= 3
                              ? 'average'
                              : 'bad'
                    }
                    focusContentColor={
                        riderCardDetails && riderCardDetails.average_rating >= 4
                            ? '#3D8C82'
                            : riderCardDetails.average_rating >= 3
                              ? '#FFA704'
                              : '#EF4444'
                    }
                />
            ),
            icon: <RiderIcon stroke='#FFFFFF' />,
        },
        {
            title: 'Total cash collected',
            highlightedTitle:
                riderCardDetails && riderCardDetails?.total_cash ? `₹${riderCardDetails?.total_cash}/-` : 'N/A',
            description: <CardDescription description={`Total cash collected by rider for selected dates`} />,
            icon: <CashCollection stroke='#ffffff' />,
        },
    ];

    const handleOrderPopup = async (orderId) => {
        try {
            setIsPopupLoading(true);
            const response = await APIV5.get(`orders/${orderId}`);
            setPopupDetails(response.order_details);
            setIsPopupLoading(false);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    function formatTime(time) {
        const [hours, minutes] = time.split(':');
        const hour = parseInt(hours, 10);
        const period = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 24 || 24;
        const formattedTime = `${formattedHour}:${minutes} ${period}`;

        return formattedTime;
    }

    const calculateTotalQuantity = (cartItems) =>
        cartItems?.reduce((totalQuantity, item) => totalQuantity + item.quantity, 0) || 0;

    function convertTimeToHMS(time) {
        const [hours, minutes, seconds] = time.split(':').map(Number);

        return `${hours} hr ${minutes} min ${seconds} sec`;
    }

    const popupOrderingMode = {
        'dine_in': 'Dine-In',
        'delivery': 'Delivery',
        'takeaway': 'Takeaway',
        'CASH_ON_DELIVERY': 'Cash',
        'CREDIT_CARD/DEBIT_CARD': 'Card',
        'CREDIT_CARD': 'Credit card',
        'DEBIT_CARD': 'Debit card',
        'UPI': 'UPI',
        'UPI_INTENT': 'UPI',
        'BALANCE': 'Balance',
    };

    const popupData = [
        {
            item: 'Order bill name',
            value: popupDetails && popupDetails?.order_bill_name ? popupDetails?.order_bill_name : 'N/A',
        },
        {
            item: 'Order date',
            value: popupDetails && popupDetails?.order_date ? formattedDate(popupDetails?.order_date) : 'N/A',
        },
        {
            item: 'Order type',
            value: popupDetails && popupDetails?.order_type ? popupOrderingMode[popupDetails?.order_type] : 'N/A',
        },
        {
            item: 'Dishes ordered',
            value:
                popupDetails && popupDetails?.cart?.cart_items
                    ? calculateTotalQuantity(popupDetails?.cart?.cart_items)
                    : 'N/A',
        },
        {
            item: "Customer's order",
            value: popupDetails && popupDetails?.customers_order ? popupDetails?.customers_order : 'N/A',
        },
        {
            item: 'Order time',
            value: popupDetails && popupDetails?.order_time ? formatTime(popupDetails?.order_time) : 'N/A',
        },
        {
            item: 'Payment method',
            value:
                popupDetails && popupDetails?.payment_method ? popupOrderingMode[popupDetails?.payment_method] : 'N/A',
        },
        {
            item: 'Delivery Time',
            value: popupDetails && popupDetails?.delivery_time ? convertTimeToHMS(popupDetails?.delivery_time) : 'N/A',
        },
        {
            item: 'Delivery Distance',
            value: popupDetails && popupDetails?.delivery_distance ? `${popupDetails?.delivery_distance} kms` : '0 kms',
        },
        {
            item: 'Delivery Cost',
            value: popupDetails && popupDetails?.delivery_cost ? `₹${popupDetails?.delivery_cost}/-` : 'N/A',
        },
    ];

    const feedbacks = {
        orderFeedback: popupDetails?.order_feedback,
        riderFeedback: popupDetails?.rider_feedback,
        date: formattedDate(popupDetails?.order_date),
    };

    const extraCharges = [
        {
            gst_amount: popupDetails?.cart?.gst_amount,
            packaging_charge: popupDetails?.cart?.packaging_charge,
            convenience_charge: popupDetails?.cart?.convenience_charge,
        },
    ];

    const otherAmount = [
        { type: 'Gross total', value: popupDetails?.gross_total?.toFixed(2) },
        {
            type: 'Other charges & taxes',
            value: (
                popupDetails?.cart?.gst_amount +
                popupDetails?.cart?.packaging_charge +
                popupDetails?.cart?.convenience_charge
            )?.toFixed(2),
        },
        {
            type: 'Delivery charge',
            value: popupDetails?.cart?.delivery_charge?.toFixed(2),
        },
    ];

    return (
        <>
            <div className='bg-[#fafafa]'>
                <div
                    className={`px-8 pb-[30px] lg:px-4 lg:pb-[23px] pt-4 w-full max-w-[1336px] bg-white mx-auto md ${
                        showTotalSalesCard && 'md:hidden'
                    }`}
                    style={{ minHeight: screenHeight - 50 }}>
                    <div>
                        <RiderDetailsTop
                            RiderIcon={RiderIcon}
                            goToRiderPage={goToRiderPage}
                            riderDetails={riderDetails}
                            isRiderTopLoading={isRiderTopLoading}
                        />
                        <Header
                            RiderIcon={RiderIcon}
                            hiddenElement='hidden'
                            LargePrimaryButton={LargePrimaryButton}
                            removeMargin='mx-[0]'
                            HeaderStyle='pt-4  border-b-0'
                            selectedDates={selectedDates}
                            setSelectedDates={setSelectedDates}
                            isRiderOrderFilter={isRiderOrderFilter}
                            setAppliedRiderOrderFilter={setAppliedRiderOrderFilter}
                            setAppliedFilter={setAppliedFilter}
                            page={'rider-details'}
                            ratingArray={ratingArray}
                            appliedRiderOrderFilter={appliedRiderOrderFilter}
                        />

                        {/* <div className='flex items-end justify-end mb-3 md:mb-0 md:z-10'>
                            <RatingGuide />
                        </div> */}

                        {!isMobileScreen ? (
                            <div className='-mx-[11px] lg:-mx-0 mb-4'>
                                {cardDetails.map((el, index) => (
                                    <div
                                        className='w-[303px] inline-block mx-2.5 lg:w-1/2 lg:mx-0 lg:even:pl-1 lg:odd:pr-1 lg:pb-0 lg:mt-2 mt-2 pb-2'
                                        key={index}>
                                        {isLoadingCards ? (
                                            <CardContentLoader />
                                        ) : (
                                            <Card
                                                title={el.title}
                                                ratingCard={el.ratingCard}
                                                heightLightedTitle={el.highlightedTitle}
                                                tag={el.tag}
                                                positive={el.positive}
                                                description={el.description}
                                                showDetailsPopup={el.showDetailsPopup}
                                                icon={el.icon}
                                                cardHeight={el.cardHeight}
                                                hasAboutDots={el.hasAboutDots}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className='-mx-4'>
                                <div>
                                    <SliderDashboard {...settingsDashboardSlider}>
                                        {cardDetails.map((el, index) => (
                                            <div key={index}>
                                                {isLoadingCards ? (
                                                    <CardContentLoader />
                                                ) : (
                                                    <Card
                                                        title={el.title}
                                                        ratingCard={el.ratingCard}
                                                        heightLightedTitle={el.highlightedTitle}
                                                        tag={el.tag}
                                                        positive={el.positive}
                                                        description={el.description}
                                                        showDetailsPopup={el.showDetailsPopup}
                                                        icon={el.icon}
                                                        cardHeight={el.cardHeight}
                                                        hasAboutDots={el.hasAboutDots}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </SliderDashboard>
                                </div>
                            </div>
                        )}
                        {!isMobileScreen ? (
                            <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                                <table className='w-full break-words'>
                                    <thead>
                                        <tr className='bg-neutral-50 text-left paragraph-overline-small text-neutral-700 shadow-innerShadow h-11 justify-center'>
                                            <th className='px-6 py-3 min-w-[130px]'>ORDER ID</th>
                                            <th className='px-6 py-3 min-w-[190px]'>BILL NAME</th>
                                            <th className='px-6 py-3 min-w-[148px]'>ORDER DATE</th>
                                            <th className='px-6 py-3 min-w-[157px]'>ORDER AMOUNT</th>
                                            <th className='pl-6 w-fit py-3 min-w-[177px]'>DELIVERY DISTANCE</th>
                                            <th className='px-6 py-3 min-w-[156px]'>DELIVERY COST</th>
                                            <th className='px-6 py-3 min-w-[158px]'>DELIVERY TIME</th>
                                            <th className='px-6 py-3 min-w-[148px]'>RIDER RATING</th>
                                            <th className='px-6 py-3 min-w-[178px]'>PAYMENT MODE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading || paginationChanged ? (
                                            <td colSpan={9}>
                                                <TableLoader
                                                    hasSwitch={false}
                                                    columns={6}
                                                />
                                            </td>
                                        ) : riderOrderDetails && riderOrderDetails.length > 0 ? (
                                            riderOrderDetails.map((el, index) => (
                                                <tr
                                                    className='paragraph-small-regular first:border-none border-t even:bg-neutral-50 h-[70px] justify-center border-neutral-300'
                                                    key={index}>
                                                    <td
                                                        className='px-6 cursor-pointer text-primary-500 hover:underline underline-offset-3'
                                                        onClick={() => {
                                                            handleClickTotalSalesCard(el?.order_id);
                                                            handleOrderPopup(el?.order_id);
                                                        }}>
                                                        {el?.order_id}
                                                    </td>
                                                    <td className='px-6 cursor-pointer'>{el?.customer_name}</td>
                                                    <td className='px-6'>{formattDated(el?.order_date)}</td>
                                                    <td className='px-6'>₹{el?.order_amount}/-</td>
                                                    <td className='px-6'>{el?.delivery_distance} kms</td>
                                                    <td className='px-6'>₹{el?.delivery_cost}/-</td>
                                                    <td className='px-6'>
                                                        <div className='flex flex-row items-center'>
                                                            <TimerIcon className='h-5 w-5 mr-1.5' />
                                                            {formatDeliveryTime(el?.delivery_time)}
                                                        </div>
                                                    </td>
                                                    <td className='px-6'>
                                                        <div className='flex flex-row'>
                                                            <OrderRatingIcon
                                                                className='h-5 w-5 mr-1.5'
                                                                fill={
                                                                    el?.rider_rating > 4
                                                                        ? '#EBF6F5'
                                                                        : el?.rider_rating >= 3
                                                                          ? '#FFF5E3'
                                                                          : '#FEF2F2'
                                                                }
                                                                stroke={
                                                                    el?.rider_rating > 4
                                                                        ? '#3D8C82'
                                                                        : el?.rider_rating >= 3
                                                                          ? '#FFA704'
                                                                          : '#EF4444'
                                                                }
                                                            />
                                                            {el?.rider_rating ? el?.rider_rating : '0.0'}
                                                        </div>
                                                    </td>
                                                    <td className='px-6'>{orderingModes(el?.payment_mode)}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={9}>
                                                    <TableBlankComponentView previewText={'No Content Available'} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        ) : isLoading || paginationChanged ? (
                            <div>
                                {[...Array(10)].map((_, index) => (
                                    <ListViewLoader
                                        key={index}
                                        hasSwitch={false}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div className='mb-12 mt-4 md:mt-[7px]'>
                                {riderOrderDetails && riderOrderDetails.length > 0 ? (
                                    riderOrderDetails.map((el, index) => (
                                        <ListViewBillDetails
                                            key={index}
                                            orderId={el?.order_id}
                                            billName={el?.customer_name}
                                            orderDate={formattDated(el?.order_date)}
                                            orderAmount={el.order_amount}
                                            deliveryDistance={el?.delivery_distance}
                                            deliveryCost={el?.delivery_cost}
                                            deliveryTime={formatDeliveryTime(el?.delivery_time)}
                                            riderRating={el?.rider_rating}
                                            paymentMode={orderingModes(el?.payment_mode)}
                                            handleClickTotalSalesCard={handleClickTotalSalesCard}
                                            handleOrderPopup={handleOrderPopup}
                                            index={index}
                                        />
                                    ))
                                ) : (
                                    <ListBlankComponentView previewText={'No Content Available'} />
                                )}
                            </div>
                        )}
                        {!_.isEmpty(riderOrderDetails) && (
                            <div className='pt-4'>
                                <PaginationNumber
                                    lastPage={paginationState.lastPage}
                                    selectedPage={paginationState.selectedPage}
                                    setSelectedPage={(value) => {
                                        handlePagination({ selectedPage: value }, 'selectedPage');
                                    }}
                                    setResultPerPage={(value) =>
                                        handlePagination({ resultPerPage: value }, 'resultPerPage')
                                    }
                                    selectedResultPerpage={paginationState.resultPerPage}
                                    isLoading={isLoading}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {showTotalSalesCard && (
                    <OrderDetailsPopup
                        isDelivered={popupDetails?.delivered}
                        code={popupDetails?.last_four_character_order_label}
                        isPopupLoading={isPopupLoading}
                        orderedDishes={popupDetails?.cart?.cart_items}
                        orderDetails={popupData}
                        isRiderFeedback={popupData[2]?.value}
                        extraCharges={extraCharges}
                        otherAmount={otherAmount}
                        netTotal={popupDetails?.total_bill_amount}
                        feedbacks={feedbacks}
                        isPaid={popupDetails?.paid}
                        page='riderDetails'
                        handleClickClose={handleClickTotalSalesCard}
                        popupDetails={popupDetails}
                    />
                )}
            </div>
        </>
    );
}
